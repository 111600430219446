<template>
  <div class="main-app" ref="app" :class="{ darkMode: darkMode }">
    <div class="main-app__inner">
      <RouterView v-if="!loading" />
      <AppLoader v-else />
    </div>
    <InstallPrompt v-show="store.promptInstall" />
    <Transition name="fade">
      <OfflineNotice v-if="!fastInternet || !isOnline" />
    </Transition>
  </div>
</template>

<script src="./MainApp.js" lang="js"></script>
