<template>
  <div class="about-m">
    <div class="about-m__back">
      <BackButton :allowBack="false" @close="close" />
    </div>
    <h2 class="title">{{ about.title }}</h2>
    <div class="about-m__image">
      <img :src="about.image" alt="" />
    </div>
    <div v-html="about.text" class="about-m__content"></div>
    <a :href="about.cta.url">
      <span class="button__light button__full-width button__arrow-right">
        <span>{{ about.cta.title }}</span>
        <img src="../../assets/icons/arrow-right-white.svg" alt="" v-if="store.darkMode" />
        <img src="../../assets/icons/arrow-right.svg" alt="" v-else />
      </span>
    </a>
  </div>
</template>

<script src="./AboutM.js"></script>
