<template>
  <div class="accordion">
    <div class="accordion__item" v-for="item in data" :key="item.uuid" :class="showId === item.uuid ? 'accordion__item__open' : ''">
      <div class="accordion__item__top" @click="openAccordion(item.uuid)">
        <p>{{ item.top }}</p>
        <img src="../../assets/icons/plus-white.svg" alt="" class="accordion__item__top__img" v-if="store.darkMode" />
        <img src="../../assets/icons/plus.svg" alt="" class="accordion__item__top__img" v-else />
      </div>
      <div class="accordion__item__bottom accordion__item__bottom--stop">
        <div v-for="item in item.bottom" :key="item.uuid" class="accordion__item__paragraph">
          <div v-if="item.type && item.type === 'text_app'" v-html="item.text"></div>
          <div v-if="item.type && item.type === 'media_app'">
            <Cover :autoHeight="true" v-if="(item.media && item.media.type && item.media.type === 'resourcespace') || item.media.type === 'image'" :image="item.media" :info="item.media.caption" />
            <div v-if="item.media && item.media.type && item.media.type === 'video_embed'" class="accordion__video">
              <iframe ref="videoEmbedRef" width="100%" height="100%" :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoID(item.media.url)}?enablejsapi=1`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div v-if="item.media && item.media.type && item.media.type === 'video'" class="accordion__video">
              <video controls width="100%">
                <source :src="item.media.url" type="video/mp4" />
              </video>
            </div>
            <div v-if="item.media && item.media.type && item.media.type === 'audio'" class="accordion__player__button" @click="openPlayer(item.media)">
              <img src="../../assets/icons/play-btn.svg" alt="play transcript" />
              <p>{{ item.media.title }}</p>
            </div>
          </div>
          <div class="accordion__activities" v-if="item.type && item.type === 'activity_teasers'">
            <div v-html="item.text"></div>
            <div class="accordion__activities__list">
              <a :href="activity.url" class="accordion__activities__list-item" v-for="activity in item.activities" :key="activity.uuid">
                <div class="accordion__activities__dates">
                  <p v-for="(date, index) in activity.dates" :key="date" class="accordion__activities__dates__date">
                    <span>{{ format(fromUnixTime(date.start), date.end ? (isSameYear(fromUnixTime(date.start), fromUnixTime(date.end)) ? (!isSameDay(fromUnixTime(date.start), fromUnixTime(date.end)) ? 'dd.MM' : 'dd.MM.yy') : 'dd.MM.yy') : 'dd.MM.yy') }}</span>
                    <img v-if="date.end && !isSameDay(fromUnixTime(date.start), fromUnixTime(date.end))" src="../../assets/icons/arrow-right.svg" alt="" />
                    <span v-if="date.end && !isSameDay(fromUnixTime(date.start), fromUnixTime(date.end))">{{ format(fromUnixTime(date.end), 'dd.MM.yy') }}</span>
                    <span v-if="index < activity.dates.length - 1">, </span>
                  </p>
                </div>
                <p class="accordion__activities__title">{{ activity.title }}</p>
              </a>
            </div>
          </div>
          <div v-if="item.type && item.type === 'specs'">
            <ArtworkSpecs modifier="tour" :artwork="item.artwork" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./StopAccordion.js"></script>
