<template>
  <div class="actionbar">
    <div class="actionbar__container">
      <div class="actionbar__item" @click="openHome">
        <img src="../../assets/icons/home-active.svg" alt="Home icon" class="actionbar__item__img" v-if="store.darkMode && homeActive && !showInfo" />
        <img src="../../assets/icons/home.svg" alt="Home icon" class="actionbar__item__img" v-else-if="store.darkMode" />
        <img src="../../assets/icons/home-white-active.svg" alt="Home icon" class="actionbar__item__img" v-else-if="homeActive && !showInfo" />
        <img src="../../assets/icons/home-white.svg" alt="Home icon" class="actionbar__item__img" v-else />
        <p class="actionbar__item__label">{{ $t('actionbar.home') }}</p>
      </div>
      <div class="actionbar__item actionbar__scan" @click="openScan">
        <div class="actionbar__scan__icon">
          <img src="../../assets/icons/scan-white.svg" alt="Scan icon" class="actionbar__item__img actionbar__item__img__scan" v-if="store.darkMode" />
          <img src="../../assets/icons/scan.svg" alt="Scan icon" class="actionbar__item__img actionbar__item__img__scan" v-else />
        </div>
        <p class="actionbar__item__label">{{ $t('actionbar.scan') }}</p>
      </div>
      <div class="actionbar__item" @click="openInfo">
        <img src="../../assets/icons/info-active.svg" alt="Info icon" class="actionbar__item__img" v-if="store.darkMode && showInfo" />
        <img src="../../assets/icons/info.svg" alt="Info icon" class="actionbar__item__img" v-else-if="store.darkMode" />
        <img src="../../assets/icons/info-white-active.svg" alt="Info icon" class="actionbar__item__img" v-else-if="showInfo" />
        <img src="../../assets/icons/info-white.svg" alt="Info icon" class="actionbar__item__img" v-else />
        <p class="actionbar__item__label">{{ $t('actionbar.info') }}</p>
      </div>
    </div>
  </div>
  <Transition name="actionbarOverlay">
    <Info v-if="showInfo" @closeInfo="closeInfo" />
    <Scan v-else-if="showScan" @closeModal="() => (showScan = false)" />
  </Transition>
</template>

<script src="./Actionbar.js"></script>
