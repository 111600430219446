<template>
  <div class="error-screen">
    <svg class="error-screen__logo" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" stroke="#000000" stroke-width="1" d="M2.99787498,0.999999992 L17.4999998,0.999999992 L20.9999998,4.50000005 L21,23 L3,23 L2.99787498,0.999999992 Z M16,1 L16,6 L21,6 M9,12 L15,18 M15,12 L9,18" />
    </svg>

    <p>
      {{ $t(error) }}
    </p>
    <RouterLink to="/">
      {{ $t('error.home') }}
    </RouterLink>
  </div>
</template>

<script src="./ErrorScreen.js"></script>
