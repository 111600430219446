<template>
  <div class="audioplayer" v-if="data">
    <button @click="onCloseClick" title="Close audio player" class="audioplayer__close">
      <img src="../../assets/icons/close.svg" alt="" />
    </button>
    <audio id="audio" ref="audioplayer" :ontimeupdate="playbackListener">
      <source :src="data.audio.url" type="audio/mpeg" />
    </audio>
    <Transition name="audioplayer__small">
      <div class="audioplayer__small" v-if="!showPlayer" :class="inFooter ? 'audioplayer__small__footer' : ''">
        <div class="audioplayer__container">
          <div class="audioplayer__small__top">
            <div class="audioplayer__small__image" @click="showPlayer = true">
              <img v-if="data.image && data.image.urlNormal" :src="data.image.urlNormal" alt="" />
              <div class="audioplayer__ph" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-audio-waveform"><path d="M2 13a2 2 0 0 0 2-2V7a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0V4a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0v-4a2 2 0 0 1 2-2" /></svg>
              </div>
            </div>
            <p class="audioplayer__small__title" @click="showPlayer = true">{{ data.audio.title }}</p>
            <div class="audioplayer__small__controls">
              <img src="../../assets/icons/rewind-white.svg" alt="" @click="rewindAudio" />
              <img v-if="!audioPaused" src="../../assets/icons/pause-white.svg" alt="" @click="pause()" />
              <img v-else src="../../assets/icons/play-white.svg" alt="" @click="play()" />
            </div>
          </div>
          <div class="audioplayer__small__bottom">
            <div class="audioplayer__small__timestamp">{{ elapsedTime }}</div>
            <div class="audioplayer__small__progressbar">
              <div class="audioplayer__small__progress" :style="{ width: `${audioProgress}%` }"></div>
            </div>
            <div class="audioplayer__small__timestamp">{{ totalTime() }}</div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition name="audioplayer__big">
      <div class="audioplayer__big" v-if="showPlayer">
        <div class="audioplayer__container">
          <div class="audioplayer__back">
            <BackButton :isWhite="true" :allowBack="false" :chevronIcon="true" @close="close" />
          </div>
          <div class="audioplayer__content">
            <div class="audioplayer__image">
              <img v-if="data.image" :src="data.image.urlNormal" alt="" />
              <div class="audioplayer__ph" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-audio-waveform"><path d="M2 13a2 2 0 0 0 2-2V7a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0V4a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0v-4a2 2 0 0 1 2-2" /></svg>
              </div>
            </div>

            <div class="audioplayer__details">
              <p v-if="data.appId" class="audioplayer__number">{{ $t('app.id.prefix') }} {{ data.appId }}</p>
              <p class="audioplayer__title">{{ data.audio.title }}</p>
              <VueSlider v-model="audioProgress" :dragOnClick="true" :height="4" :max="100" :min="0" :processStyle="{ backgroundColor: '#FFFFFF' }" :railStyle="{ backgroundColor: '#4D4D4F' }" :tooltip="'none'" />
              <div class="audioplayer__timestamps">
                <p>{{ elapsedTime }}</p>
                <p>{{ totalTime() }}</p>
              </div>
            </div>

            <div class="audioplayer__controls">
              <img class="audioplayer__controls__side" src="../../assets/icons/rewind-white.svg" alt="" @click="rewindAudio" />
              <img class="audioplayer__controls__middle" v-if="!audioPaused" src="../../assets/icons/pause-white.svg" alt="" @click="pause()" />
              <img class="audioplayer__controls__middle" v-else src="../../assets/icons/play-white.svg" alt="" @click="play()" />
              <img class="audioplayer__controls__side" src="../../assets/icons/forward-white.svg" alt="" @click="forwardAudio" />
            </div>

            <div class="audioplayer__bottom" @click="showTranscript = true" v-if="data.audio.transcription">
              <p>{{ $t('audioplayer.transcriptShow') }}</p>
              <img src="../../assets/icons/chevron.svg" alt="" />
            </div>
          </div>

          <Transition name="transcript">
            <div class="audioplayer__transcript" v-if="showTranscript">
              <div class="audioplayer__transcript__top" @click="showTranscript = false">
                <p>{{ $t('audioplayer.transcriptHide') }}</p>
                <img src="../../assets/icons/chevron.svg" alt="" v-if="store.darkMode" />
                <img src="../../assets/icons/chevron-black.svg" alt="" v-else />
              </div>
              <div class="audioplayer__transcript__bottom">
                <div v-html="data.audio.transcription"></div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script src="./Audioplayer.js"></script>
