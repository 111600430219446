import mainStore from '../../../stores/mainStore';
import Accordion from '../../Accordion/Accordion.vue';
import Actionbar from '../../Actionbar/Actionbar.vue';
import Audioplayer from '../../Audioplayer/Audioplayer.vue';
import BackButton from '../../BackButton/BackButton.vue';
import Cover from '../../Cover/Cover.vue';
import ContentLoader from '../../ContentLoader/ContentLoader.vue';
import ArtworkSpecs from '../../ArtworkSpecs/ArtworkSpecs.vue';
import ErrorScreen from '../../ErrorScreen/ErrorScreen.vue';

export default {
  props: {
    id: {
      type: String,
    },
  },

  components: {
    Accordion,
    Actionbar,
    Audioplayer,
    BackButton,
    Cover,
    ContentLoader,
    ArtworkSpecs,
    ErrorScreen,
  },

  mounted() {
    if (!this.artwork) {
      this.store.getArtworks(this.id)?.then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      showPlayer: false,
      overlayVisible: false,
      showImgInfo: false,
      loading: true,
    };
  },

  computed: {
    artwork: {
      get() {
        return this.store.artworks[this.id];
      },
    },
    error: {
      get() {
        return this.store.errors.artworks[this.id];
      },
    },
    accordionData: {
      cache: false,
      get() {
        const arr = [];

        if (this.artwork.body) {
          arr.push({
            top: this.$t('artwork.info'),
            bottom: this.artwork.body,
          });
        }
        if (this.artwork.video) {
          arr.push({
            top: this.$t('artwork.video'),
            bottom: this.artwork.video,
          });
        }
        arr.push({
          top: this.$t('artwork.specifications'),
          bottom: true,
          name: 'specs',
        });

        return arr;
      },
    },
    artists: {
      get() {
        const arr = [];
        if (this.artwork.creators) {
          this.artwork.creators.map((creator) => {
            arr.push(creator.name);
            return creator;
          });
          return arr.join(', ');
        }
        return arr;
      },
    },
  },

  methods: {
    togglePlayer() {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.cleanupListeners();
      }
      this.showPlayer = !this.showPlayer;

      if (this.showPlayer && this.store.audioData !== this.artwork) {
        this.store.playAudio(this.artwork);
      }
    },
  },

  watch: {
    $route() {
      // If we are looking for an artwork, while on an artwork entry,
      // we need to watch the route change and peform a new fetch,
      // as Vue reuses the component.
      if (!this.artwork) {
        this.store.getArtworks(this.id)?.then(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
