export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },

  components: {},

  mounted() {},

  data() {
    return {};
  },

  computed: {},

  methods: {
    goToStep(index) {
      this.$emit('update:currentStep', index);
    },
  },

  watch: {},
};
