export default {
  props: {
    info: {
      type: String,
    },
    image: {
      type: Object,
    },
    isBackground: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Boolean,
      default: false,
    },
    isRoom: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  mounted() {
    refreshFsLightbox();
  },

  data() {
    return {
      showInfo: false,
    };
  },

  computed: {},

  methods: {},

  watch: {
    image() {
      this.$nextTick(() => {
        refreshFsLightbox();
      });
    },
  },
};
