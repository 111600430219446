<template>
  <div class="options-list">
    <div class="options-list__inner">
      <div v-for="(option, index) in options" :key="index" class="options-list__item" :class="option.hasToggle ? '' : 'options-list__item__vertical'">
        <p class="options-list__text">{{ option.text }}</p>
        <template v-if="option.hasToggle">
          <ToggleSwitch v-model="option.value" />
        </template>
        <template v-else>
          <div class="options-list__text-scale">
            <p class="text-scale__small">A</p>
            <div class="options-list__slider">
              <VueSlider
                v-model="option.value"
                :adsorb="true"
                :dotOptions="[
                  {
                    tooltip: 'none',
                    style: { backgroundColor: dotColor, width: '16px', height: '16px', boxShadow: 'none' },
                    focusStyle: { boxShadow: 'none' },
                  },
                ]"
                :dragOnClick="true"
                :included="true"
                :labelStyle="{ display: 'none' }"
                :marks="true"
                :max="2"
                :processStyle="{ backgroundColor: dotColor }"
                :railStyle="{ backgroundColor: '#C7C8CA' }"
                :stepStyle="{ display: 'block', width: '4px', borderRadius: '2px', height: '10px', top: '-75%', backgroundColor: '#C7C8CA' }"
                :stepActiveStyle="{ backgroundColor: dotColor }"
                width="100%"
                @change="onSliderChange"
              />
            </div>
            <p class="text-scale__large">A</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script src="./OptionsList.js"></script>
