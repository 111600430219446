<template>
  <div class="pagination">
    <div
      v-for="(step, index) in steps"
      :key="index"
      @click="goToStep(index)"
      :class="{
        active: index === currentStep,
        black: index === currentStep,
      }"
    ></div>
  </div>
</template>

<script src="./Pagination.js"></script>
