<template>
  <div class="accessibility">
    <div class="accessibility__inner">
      <div class="accessibility__header">
        <BackButton />
        <h2 class="accessibility__title">{{ accessibility.title }}</h2>
        <div v-html="accessibility.text"></div>
      </div>
      <div class="accessibility__options">
        <OptionsList :options="accessibilityOptions" />
      </div>
    </div>
  </div>
</template>

<script src="./Accessibility.js"></script>
