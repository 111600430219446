<template>
  <div :class="`${modifier}__specifications__wrapper`">
    <p :class="`${modifier}__specifications__title`">{{ $t('specs.header') }}</p>
    <div v-for="group in specs" :key="group.label" :class="`${modifier}__specification`">
      <!-- <p :class="`${modifier}__specification__title`">{{ group.label }}</p> -->
      <div v-for="item in group.items" :key="item.label" :class="`${modifier}__specification__item`">
        <p :class="`${modifier}__specs__title`">{{ item.label }}</p>
        <p :class="`${modifier}__specs__detail`" v-html="item.value"></p>
      </div>
    </div>
  </div>
</template>

<script src="./ArtworkSpecs.js"></script>
