import mainStore from '../../stores/mainStore';
import BackButton from '../BackButton/BackButton.vue';

export default {
  props: {},

  components: {
    BackButton,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  computed: {
    about: {
      get() {
        return this.store.settings.about;
      },
    },
  },

  emits: ['closeOverlay'],

  methods: {
    close() {
      this.$emit('closeOverlay', 'aboutM');
    },
  },

  watch: {},
};
