<template>
  <div class="post-visit">
    <div class="post-visit__back">
      <BackButton :allowBack="false" @close="close" />
    </div>
    <div class="post-visit__body">
      <Paragraphs @playAudio="playAudio" :paragraphs="visited" />
    </div>
  </div>
</template>

<script src="./PostVisit.js"></script>
