import { fromUnixTime, format, isSameYear, isSameDay } from 'date-fns';
import mainStore from '../../../stores/mainStore';
import Accordion from '../../Accordion/Accordion.vue';
import BackButton from '../../BackButton/BackButton.vue';
import Newsletter from '../../Newsletter/Newsletter.vue';
import PostVisit from '../../PostVisit/PostVisit.vue';
import Paragraphs from '../../Paragraphs/Paragraphs.vue';
import Audioplayer from '../../Audioplayer/Audioplayer.vue';

export default {
  props: {
    id: {
      type: String,
    },
  },

  components: {
    Accordion,
    BackButton,
    Newsletter,
    PostVisit,
    Paragraphs,
    Audioplayer,
  },

  mounted() {
    if (!this.tour) {
      this.store.getTour(this.id).then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      showNewsletter: false,
      showPostVisit: false,
      showPlayer: false,
    };
  },

  computed: {
    tour: {
      get() {
        return this.store.tours[this.id] ? this.store.tours[this.id].tour : null;
      },
    },
    hasPostVisitContent: {
      get() {
        return this.store.settings.visited ? this.store.settings.visited.length : null;
      },
    },
    accordionData: {
      cache: false,
      get() {
        return [
          {
            top: this.$t('tour.expected'),
            bottom: this.tour.activities && this.tour.activities.length ? 'slot' : null,
          },
        ];
      },
    },
  },

  methods: {
    format,
    fromUnixTime,
    isSameYear,
    isSameDay,
    closeOverlay(val) {
      switch (val) {
        case 'newsletter':
          this.showNewsletter = false;
          break;
        case 'postVisit':
          this.showPostVisit = false;
          break;
        default:
          break;
      }
    },
    togglePlayer(data) {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.cleanupListeners();
      }

      if (this.showPlayer && this.store.audioData !== data) {
        this.store.playAudio(data);
      } else if (this.showPlayer && this.store.audioData === data) {
        this.showPlayer = !this.showPlayer;
      } else {
        this.showPlayer = !this.showPlayer;
        this.store.playAudio(data);
      }
    },
  },

  watch: {},
};
