<template>
  <div class="languages">
    <div class="languages__back">
      <BackButton v-if="this.store.language" />
      <img v-else-if="this.store.darkMode" class="languages__logo" src="../../../assets/icons/logo-white.svg" alt="Logo" />
      <img v-else class="languages__logo" src="../../../assets/icons/logo.svg" alt="Logo" />
    </div>

    <h2 class="languages__title title">{{ this.store.language ? $t('languages.title') : $t('languages.select-title') }}</h2>
    <div class="languages__list">
      <div @click="onLangClick(lang.val)" class="languages__list-item" v-for="lang in languageOptions" :key="lang.val">
        <p>{{ lang.label }}</p>
        <img src="./../../../assets/icons/arrow-right-white.svg" v-if="store.darkMode" alt="Right arrow" />
        <img src="./../../../assets/icons/arrow-right.svg" v-else alt="Right arrow" />
      </div>
    </div>
  </div>
</template>

<script src="./Languages.js"></script>
