<template>
  <div class="page-wrapper" :class="{ noscroll: overlayVisible }">
    <div class="artwork" v-if="artwork">
      <div class="artwork__back">
        <BackButton :isWhite="true" />
      </div>

      <Cover v-if="artwork.image" :info="artwork.image.caption" :image="artwork.image" :isBackground="true" imageHeight="'32.5rem'" />
      <div v-else class="image-placeholder"></div>

      <div class="artwork__details">
        <h1 class="title">{{ artwork.title }}</h1>
        <div class="artwork__artist__wrapper">
          <p class="artwork__artist" v-if="artists">{{ artists }}</p>
          <p class="artwork__artist">{{ artwork.startDatePrecision ? artwork.startDatePrecision : '' }} {{ artwork.startDate }}</p>
        </div>
        <div class="artwork__details__wrapper">
          <p v-if="artwork.appId">{{ $t('app.id.prefix') }} {{ artwork.appId }}</p>
          <p>{{ artwork.location }}</p>
        </div>
        <div class="artwork__player__button" @click="togglePlayer" v-if="artwork.audio">
          <img src="../../../assets/icons/play-btn.svg" alt="play transcript" />
          <p>{{ artwork.audio.title }}</p>
        </div>
        <div class="artork__intro" v-if="artwork.intro" v-html="artwork.intro"></div>
      </div>

      <div class="artwork__accordion" :class="showPlayer ? 'artwork__accordion__player' : ''">
        <Accordion :data="accordionData">
          <template #content-specs>
            <ArtworkSpecs :artwork="artwork" />
          </template>
        </Accordion>
      </div>
    </div>
    <ContentLoader v-else-if="!error" />
    <ErrorScreen v-else :error="error" />
    <Transition name="audioplayer">
      <Audioplayer ref="audioPlayer" :data="artwork" v-show="showPlayer" @closeAudioPlayer="togglePlayer" @addNoScroll="(val) => (overlayVisible = val)" />
    </Transition>
    <Actionbar @addNoScroll="(val) => (overlayVisible = val)" />
  </div>
</template>

<script src="./Artwork.js"></script>
