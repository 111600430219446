import nl from '../translations/nl';
import fr from '../translations/fr';
import en from '../translations/en';

const T = {
  install: (app) => {
    app.config.globalProperties.$t = function (string) {
      const translations = {
        nl,
        fr,
        en,
      };

      return translations[document.documentElement.lang] ? translations[document.documentElement.lang][string] : translations.en[string];
    };
  },
};

export default T;
