import mainStore from '../../stores/mainStore';

export default {
  props: {},

  components: {},

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  computed: {},

  methods: {
    clickHandler() {
      this.store.showInstallPrompt();
    },
  },

  watch: {},
};
