// import './MainApp.scss';
import mainStore from '../../stores/mainStore';
import AppLoader from '../../components/AppLoader/AppLoader.vue';
import InstallPrompt from '../../components/InstallPrompt/InstallPrompt.vue';
import OfflineNotice from '../../components/OfflineNotice/OfflineNotice.vue';

export default {
  props: {},

  components: {
    AppLoader,
    InstallPrompt,
    OfflineNotice,
  },

  beforeMount() {
    // some config for previews
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    const bearer = urlParams.get('_bearer-token');
    if (lang) {
      this.store.setLanguage(lang);
    }

    if (bearer) {
      this.store.setOnboarded(true);
    }

    if (this.store.language) {
      // we set the initial language
      document.documentElement.setAttribute('lang', this.store.language);
    }

    // call initial app data
    this.loadInitialData();
  },

  mounted() {
    document.body.style.setProperty('--letterSize', this.store.letterSize);

    const zoomStatus = this.store.allowZoom ? ', user-scalable=yes' : ', user-scalable=no';
    document.querySelector('meta[name="viewport"]').setAttribute('content', `width=device-width, initial-scale=1.0, maximum-scale=6.0${zoomStatus}`);

    this.$router.beforeEach((to, from) => {
      if (!to.matched.length) {
        return { name: 'home' };
      }

      if (!this.store.language && to.name !== 'languages') {
        if (!this.store.entryRoute && to.name !== 'onboarding') {
          this.store.setEntryRoute(to.fullPath);
        }
        return { name: 'languages' };
      }

      if (this.store.language) {
        // initial setup after we have a language (onboarding);
        if (to.name !== 'onboarding') {
          if (!this.store.entryRoute && to.name !== 'languages') {
            this.store.setEntryRoute(to.fullPath);
          }

          if (!this.store.onboarded && to.name !== 'accessibility') {
            return { name: 'onboarding' };
          }
        }
      }

      if (to.name === 'onboarding' && this.store.onboarded && from.name !== 'home') {
        return { name: 'home' };
      }

      return true;
    });

    navigator.connection?.addEventListener('change', () => {
      this.fastInternet = navigator.connection?.type === 'wifi' || navigator.connection?.effectiveType === '3g' || navigator.connection?.effectiveType === '4g' || navigator.connection?.effectiveType === '5g';
    });

    // Safari fallbacks
    window.addEventListener('online', () => {
      this.isOnline = true;
    });

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });

    // add listeners for pulldown
    document.addEventListener('touchstart', this.onTouchStart);
    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);

    // set audio player in store;
    this.store.setAudioPlayer(this.$refs.audioplayer);
  },

  data() {
    const store = mainStore();

    return {
      store,
      title: 'M Leuven App',
      loading: true,
      fastInternet: navigator.connection?.type === 'wifi' || navigator.connection?.effectiveType === '3g' || navigator.connection?.effectiveType === '4g' || navigator.connection?.effectiveType === '5g' || true,
      isOnline: true,
      refreshOpacity: 0,
      // pull to refresh
      touchStartY: 0,
      touchCurrentY: 0,
      isPullingDown: false,
      contentRefreshed: false,
    };
  },

  computed: {
    darkMode: {
      cache: false,
      get() {
        return this.store.darkMode;
      },
    },
    letterSize: {
      cache: false,
      get() {
        return this.store.letterSize;
      },
    },
    settings: {
      get() {
        return this.store.settings;
      },
    },
    audioSource: {
      get() {
        return this.store.audioSource;
      },
    },
  },

  methods: {
    loadInitialData() {
      // call initial app data
      this.loading = true;
      let loaded = false;

      this.store.getSettings().then(() => {
        if (loaded) {
          this.loading = false;
        } else {
          loaded = true;
        }
      });
      window.setTimeout(() => {
        if (loaded) {
          this.loading = false;
        } else {
          loaded = true;
        }
      }, 1500);
    },

    onTouchStart(e) {
      if (window.scrollY === 0) {
        this.touchStartY = e.touches[0].clientY;
        this.isPullingDown = true;
      }
    },
    onTouchMove(e) {
      if (this.isPullingDown) {
        this.touchCurrentY = e.touches[0].clientY;
        if (this.touchCurrentY - this.touchStartY > 200 && this.store.audioPlayer === null) {
          this.store.resetData();
        }
      }
    },
    onTouchEnd() {
      this.isPullingDown = false;
    },
  },

  beforeDestroy() {
    document.removeEventListener('touchstart', this.onTouchStart);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
  },

  watch: {
    settings(val) {
      if (!val) {
        this.loadInitialData();
      }
    },
  },
};
