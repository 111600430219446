import mainStore from '../../stores/mainStore';
import BackButton from '../BackButton/BackButton.vue';
import Paragraphs from '../Paragraphs/Paragraphs.vue';

export default {
  props: {},

  components: {
    BackButton,
    Paragraphs,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  computed: {
    visited: {
      get() {
        return this.store.settings.visited;
      },
    },
  },

  emits: ['closeOverlay', 'playAudio'],

  methods: {
    close() {
      this.$emit('closeOverlay', 'postVisit');
    },
    playAudio(data) {
      this.$emit('playAudio', data);
    },
  },

  watch: {},
};
