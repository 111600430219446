<template>
  <div class="floorplan" :class="{ floorplan__modal: !isFullScreen }">
    <div class="floorplan__back">
      <BackButton :allowBack="false" @close="close" />
    </div>
    <div class="floorplan__header" v-html="floorplans.intro"></div>
    <div class="floorplan__list">
      <div class="floorplan__list-item" v-for="(floor, id) in floorplans.items" :class="activeFloor === id ? 'floorplan__list-item__active' : ''" @click="activeFloor = id">
        <p>{{ floor.title }}</p>
      </div>
    </div>
    <div class="floorplan__image" v-for="(floor, id) in floorplans.items">
      <img :src="floor.image" alt="" v-show="id === activeFloor" />
    </div>
  </div>
</template>

<script src="./Floorplan.js"></script>
