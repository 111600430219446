<template>
  <div class="info" :class="{ info__overlay: hasOverlay }">
    <div class="info__top">
      <img src="../../assets/icons/close-white.svg" alt="" @click="closeInfo" v-if="store.darkMode" />
      <img src="../../assets/icons/close.svg" alt="" @click="closeInfo" v-else />
    </div>
    <div class="info__header">
      <h2 class="title">{{ info.title }}</h2>
      <p>{{ info.intro }}</p>
    </div>
    <div class="info__list">
      <div class="info__list-item" v-if="floorplanHasData" @click="showFloorplan = true">
        <p>{{ $t('info.floorplan') }}</p>
        <img src="../../assets/icons/arrow-right-white.svg" alt="Right arrow" v-if="store.darkMode" />
        <img src="../../assets/icons/arrow-right.svg" alt="Right arrow" v-else />
      </div>
      <RouterLink :to="{ name: 'onboarding' }" class="info__list-item">
        <p>{{ $t('info.onboarding') }}</p>
        <img src="../../assets/icons/arrow-right-white.svg" alt="Right arrow" v-if="store.darkMode" />
        <img src="../../assets/icons/arrow-right.svg" alt="Right arrow" v-else />
      </RouterLink>
      <div class="info__list-item" @click="showNewsletter = true">
        <p>{{ $t('info.newsletter') }}</p>
        <img src="../../assets/icons/arrow-right-white.svg" alt="Right arrow" v-if="store.darkMode" />
        <img src="../../assets/icons/arrow-right.svg" alt="Right arrow" v-else />
      </div>
      <div class="info__list-item" @click="showAboutM = true">
        <p>{{ $t('info.about') }}</p>
        <img src="../../assets/icons/arrow-right-white.svg" alt="Right arrow" v-if="store.darkMode" />
        <img src="../../assets/icons/arrow-right.svg" alt="Right arrow" v-else />
      </div>
    </div>
    <Floorplan v-if="showFloorplan && floorplanHasData" @closeOverlay="closeOverlay" />
    <Newsletter v-if="showNewsletter" @closeOverlay="closeOverlay" />
    <AboutM v-if="showAboutM" @closeOverlay="closeOverlay" />
  </div>
</template>

<script src="./Info.js"></script>
