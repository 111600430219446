// eslint-disable-next-line import/no-extraneous-dependencies
import { fromUnixTime, format, isSameYear, isSameDay } from 'date-fns';
import mainStore from '../../stores/mainStore';
import Cover from '../Cover/Cover.vue';
import ArtworkSpecs from '../ArtworkSpecs/ArtworkSpecs.vue';

export default {
  props: {
    data: {
      type: Array,
    },
  },

  components: {
    Cover,
    ArtworkSpecs,
  },

  mounted() {
    // Reset accordions that persist between pages
    this.$router.beforeEach(() => {
      this.showId = null;
    });
  },

  data() {
    const store = mainStore();

    return {
      store,
      showId: null,
    };
  },

  computed: {},

  methods: {
    format,
    fromUnixTime,
    isSameYear,
    isSameDay,
    extractYouTubeVideoID(url) {
      const regExp = /youtu\.be\/([a-zA-Z0-9_-]{11})/;
      const fullRegExp = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
      let match = null;
      if (url.match(regExp)) {
        match = url.match(regExp);
      } else {
        match = url.match(fullRegExp);
      }
      return match && match[1] ? match[1] : null;
    },
    openAccordion(id) {
      if (this.$refs.videoEmbedRef) {
        this.$refs.videoEmbedRef.forEach((video) => {
          video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        });
      }
      if (this.showId === id) {
        this.showId = null;
      } else {
        this.showId = id;
      }
    },
    openPlayer(media) {
      this.$emit('openAccordionAudio', media);
    },
  },

  watch: {},
};
