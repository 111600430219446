export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  components: {},

  mounted() {},

  data() {
    return {};
  },

  computed: {},

  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },

  watch: {},
};
