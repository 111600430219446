import { createApp } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { GesturePlugin } from '@vueuse/gesture';
import { TippyPlugin } from 'tippy.vue';
import { VueQrcodeReader } from 'vue-qrcode-reader';
import { registerSW } from 'virtual:pwa-register';
import MainApp from './apps/MainApp/MainApp.vue';

import Home from './components/Views/Home/Home.vue';
import Accessibility from './components/Views/Accessibility/Accessibility.vue';
import Artwork from './components/Views/Artwork/Artwork.vue';
import Expo from './components/Views/Expo/Expo.vue';
import Languages from './components/Views/Languages/Languages.vue';
import Onboarding from './components/Views/Onboarding/Onboarding.vue';
import Settings from './components/Views/Settings/Settings.vue';
import Tours from './components/Views/Tours/Tours.vue';
import TourEnd from './components/Views/TourEnd/TourEnd.vue';
import TourStop from './components/Views/TourStop/TourStop.vue';

import T from './plugins/translate-plugin';
import './sass/main.scss';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      if (registration.active && registration.active.scriptURL.endsWith('my-sw.js')) {
        registration.unregister().then((success) => {
          if (success) {
            console.log('Service worker sw.js unregistered successfully.');
          } else {
            console.log('Failed to unregister service worker sw.js.');
          }
        });
      }
    }
  });
}

// update service worker on new deploy
registerSW({ immediate: true });

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
  },
  {
    path: '/accessibility',
    component: Accessibility,
    name: 'accessibility',
  },
  {
    path: '/artwork/:id',
    component: Artwork,
    name: 'artwork',
    props: true,
  },
  {
    path: '/expo/:id',
    component: Expo,
    name: 'expo',
    props: true,
  },
  {
    path: '/languages',
    component: Languages,
    name: 'languages',
    props: true,
  },
  {
    path: '/onboarding',
    component: Onboarding,
    name: 'onboarding',
  },
  {
    path: '/settings',
    component: Settings,
    name: 'settings',
  },
  {
    path: '/tour/:id',
    component: Tours,
    name: 'tours',
    props: true,
  },
  {
    path: '/tour/:tourId/stop/:index',
    component: TourStop,
    name: 'tour-stop',
    props: true,
  },
  {
    path: '/tour-end/:id',
    component: TourEnd,
    name: 'tour-end',
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(MainApp);

if (window.location.origin.includes('bezoek.mleuven.be') || window.location.origin.includes('visitez.mleuven.be') || window.location.origin.includes('visit.mleuven.be')) {
  Sentry.init({
    app,
    dsn: 'https://8b217885c4c1f73d653a080717efa6d3@o146464.ingest.us.sentry.io/4507508613775360',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/bezoek\.mleuven\.be/, /^https:\/\/visitez\.mleuven\.be/, /^https:\/\/visit\.mleuven\.be/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router);
app.use(pinia);
app.use(GesturePlugin);
app.use(T);
app.use(TippyPlugin);
app.use(VueQrcodeReader);
app.mount('#app');
