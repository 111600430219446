<template>
  <div class="tour">
    <div :key="stop.uuid" class="tour__wrapper" v-if="stop && tour">
      <div class="tour__header">
        <RouterLink replace class="tour__back" :to="{ name: 'tours', params: { id: tourId } }" tabindex="0" role="button" aria-pressed="false">
          <img src="../../../assets/icons/close-white.svg" alt="" v-if="store.darkMode" />
          <img src="../../../assets/icons/close.svg" alt="" v-else />
          <p>{{ $t('tour.stop') }}</p>
        </RouterLink>
        <div class="tour__options" v-tippy:options>
          <img src="../../../assets/icons/options-white.svg" alt="" v-if="store.darkMode" />
          <img src="../../../assets/icons/options.svg" alt="" v-else />
        </div>
        <tippy
          target="options"
          trigger="click"
          :hide-on-click="true"
          :interactive="true"
          placement="bottom-start"
          :extra="{
            arrow: false,
            theme: 'light options',
            role: 'tooltip',
          }"
        >
          <div class="tour__options__list">
            <div class="tour__options__list__item" @click="() => (showExitTour = true)">
              <img src="../../../assets/icons/exit.svg" alt="" />
              <p>{{ $t('tour.overlay.ending') }}</p>
            </div>

            <div class="tour__options__list__item" v-if="floorplanHasData" @click="() => (showFloorplan = true)">
              <img src="../../../assets/icons/floorplan.svg" alt="" />
              <p>{{ $t('tour.overlay.floorplan') }}</p>
            </div>
            <!-- TODO: overview for tour stops -->
            <div class="tour__options__list__item" @click="() => (showTourOverview = true)">
              <img src="../../../assets/icons/overview.svg" alt="" />
              <p>{{ $t('tour.overlay.overview') }}</p>
            </div>
            <div class="tour__options__list__item" @click="() => (showScan = true)">
              <img src="../../../assets/icons/scan.svg" alt="" />
              <p>{{ $t('tour.overlay.scan') }}</p>
            </div>
            <div class="tour__options__list__item" @click="() => (showSettings = true)">
              <img src="../../../assets/icons/settings.svg" alt="" />
              <p>{{ $t('tour.overlay.settings') }}</p>
            </div>
          </div>
        </tippy>
      </div>

      <div v-if="!isArtworkStop" class="tour__content tour__content--default">
        <div class="tour__content__top">
          <p v-if="tour.title">{{ tour.title }}</p>
        </div>
        <Cover v-if="stop.image" :info="stop.image.caption" :image="stop.image" />
        <div class="image-placeholder" v-else></div>

        <div class="tour__content__wrapper">
          <p class="title title--stop">{{ stop.title }}</p>
          <div v-html="stop.text" class="tour__content__text"></div>

          <div class="tour__player__button" v-if="stop.audio" @click="openPlayer">
            <img src="../../../assets/icons/play-btn.svg" alt="play transcript" />
            <p>{{ stop.audio.title }}</p>
          </div>
        </div>
        <div class="tour__accordion">
          <StopAccordion :data="accordionData" @openAccordionAudio="openPlayer" />
        </div>
      </div>
      <div v-else class="tour__content">
        <div class="tour__content__top">
          <p v-if="tour.title">{{ tour.title }}</p>
        </div>
        <Cover v-if="stop.artwork.image" :info="stop.artwork.image.caption" :image="stop.artwork.image" />
        <div class="image-placeholder" v-else></div>
        <div class="tour__content__wrapper">
          <p v-if="stop.artwork.appId" class="app-id">{{ $t('app.id.prefix') }} {{ stop.artwork.appId }}</p>
          <p class="title title--stop">{{ stop.artwork.title }}</p>
          <p class="creator">{{ artworkCreators }}</p>
          <div v-html="stop.text" class="tour__content__text"></div>

          <div class="tour__player__button" v-if="stop.audio" @click="openPlayer">
            <img src="../../../assets/icons/play-btn.svg" alt="play transcript" />
            <p>{{ stop.audio.title }}</p>
          </div>
          <div class="tour__player__button" v-else-if="stop.artwork.audio" @click="openPlayer">
            <img src="../../../assets/icons/play-btn.svg" alt="play transcript" />
            <p>{{ stop.artwork.audio.title }}</p>
          </div>
          <div class="tour__intro" v-if="stop.intro" v-html="stop.intro"></div>
          <div class="tour__intro" v-else-if="stop.artwork.intro" v-html="stop.artwork.intro"></div>
        </div>
        <div class="tour__accordion">
          <StopAccordion :data="artworkAccordionData" @openAccordionAudio="openPlayer" />
        </div>
      </div>

      <div class="tour__footer">
        <Transition name="audioplayer" mode="out-in">
          <Audioplayer ref="audioPlayer" :key="audioKey" v-show="showPlayer" :inFooter="true" @closeAudioPlayer="togglePlayer" class="tour__audioplayer" />
        </Transition>

        <div class="tour__navigation">
          <div class="tour__navigation__left">
            <RouterLink class="button__icon button__white" replace :to="{ name: 'tour-stop', params: { tourId, index: prevIndex } }" v-if="store.darkMode && index > 1" @click.native="closePlayer">
              <img src="../../../assets/icons/arrow-left-white.svg" alt="" />
            </RouterLink>
            <RouterLink class="button__icon" replace :to="{ name: 'tour-stop', params: { tourId, index: prevIndex } }" v-else-if="index > 1" @click.native="closePlayer">
              <img src="../../../assets/icons/arrow-left-white.svg" alt="" />
            </RouterLink>
          </div>
          <p>{{ index + '/' + stepsNo }}</p>
          <div v-if="index < stepsNo" class="tour__navigation__right">
            <RouterLink class="button__icon" replace :to="{ name: 'tour-stop', params: { tourId, index: nextIndex } }" v-if="store.darkMode" @click.native="closePlayer">
              <img src="../../../assets/icons/arrow-right.svg" alt="" />
            </RouterLink>
            <RouterLink class="button__icon button__white" replace :to="{ name: 'tour-stop', params: { tourId, index: nextIndex } }" @click.native="closePlayer" v-else>
              <img src="../../../assets/icons/arrow-right.svg" alt="" />
            </RouterLink>
          </div>
          <div v-else class="tour__navigation__right">
            <RouterLink class="button" replace :to="{ name: 'tour-end', params: { id: tourId } }" v-if="store.darkMode" @click.native="closePlayer">
              <img src="../../../assets/icons/arrow-right.svg" alt="" />
            </RouterLink>
            <RouterLink replace class="button__icon button__white" :to="{ name: 'tour-end', params: { id: tourId } }" v-else @click.native="closePlayer">
              <img src="../../../assets/icons/arrow-right.svg" alt="" />
            </RouterLink>
          </div>
        </div>
      </div>

      <div class="tour__overlay__background" v-if="hasOverlay" @click="closeModal('all')"></div>
    </div>
    <ContentLoader v-else />
  </div>
  <ExitTourOverlay v-if="showExitTour" @closeModal="closeModal" @closeTour="closeTour" />
  <Transition name="floorplan">
    <Floorplan v-if="showFloorplan" :isFullScreen="false" @closeOverlay="closeModal" />
    <OverviewTourOverlay v-else-if="showTourOverview" @closeModal="closeModal" :subtitle="tour.title" :tourId="tour.uuid" :index="Number(index)" />
    <Scan v-else-if="showScan" @closeModal="closeModal" />
  </Transition>
  <Settings v-if="showSettings" :isModal="true" @closeModal="closeModal" />
</template>

<script src="./TourStop.js"></script>
