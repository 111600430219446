import Cover from '../Cover/Cover.vue';
import mainStore from '../../stores/mainStore';

export default {
  components: {
    Cover,
  },

  props: {
    paragraphs: {
      type: Array,
      required: true,
    },
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  emits: ['playAudio'],

  methods: {
    extractYouTubeVideoID(url) {
      const regExp = /youtu\.be\/([a-zA-Z0-9_-]{11})/;
      const fullRegExp = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
      let match = null;
      if (url.match(regExp)) {
        match = url.match(regExp);
      } else {
        match = url.match(fullRegExp);
      }
      return match && match[1] ? match[1] : null;
    },
    playAudio(audio) {
      console.log(audio);
      this.$emit('playAudio', { audio, image: null });
    },
  },
};
