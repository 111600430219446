<template>
  <div class="page-wrapper" :class="{ noscroll: overlayVisible }">
    <div class="tours" v-if="tour && !tourOpen">
      <div class="tours__back">
        <BackButton :isWhite="true" />
      </div>

      <Cover v-if="tour.headerImage" :info="tour.headerImage.caption" :image="tour.headerImage" @addNoScroll="(val) => (overlayVisible = val)" />

      <div class="tours__content">
        <div class="tours__details">
          <h1 class="title">{{ tour.title }}</h1>
          <div class="tours__details__wrapper">
            <p>{{ tour.duration }}</p>
            <p>{{ tour.stopCount }} stops</p>
          </div>

          <RouterLink replace class="button__light button__full-width button__arrow-right" :to="{ name: 'tour-stop', params: { tourId: tour.uuid, index: 1 } }">
            <span>{{ $t('tour.start') }}</span>
            <img src="../../../assets/icons/arrow-right.svg" alt="" />
          </RouterLink>

          <div v-html="tour.intro"></div>
        </div>

        <div class="tours__list__wrapper">
          <h2 class="subtitle">{{ $t('tour.content') }}</h2>

          <div class="tours__list">
            <RouterLink v-for="stop in tour.featuredStops" :key="stop.uuid" :to="{ name: 'tour-stop', params: { tourId: tour.uuid, index: stop.index + 1 } }">
              <div class="tours__item" v-if="!stop.isAction">
                <div class="tours__item__image" v-if="stop.type === 'default'">
                  <img :src="stop.image" alt="" />
                </div>
                <div class="tours__item__image" v-if="stop.type === 'artwork'">
                  <img :src="stop.artwork.image" alt="" />
                </div>
                <div v-if="stop.type === 'default'" class="tours__item__content tours__item__content__default">
                  <p class="tours__item__title">{{ stop.title }}</p>
                  <div class="tours__item__icons">
                    <span v-if="stop.audio">
                      <img src="../../../assets/icons/audio-white.svg" alt="" v-if="store.darkMode" />
                      <img src="../../../assets/icons/audio.svg" alt="" v-else />
                    </span>
                    <span v-if="stop.video">
                      <img src="../../../assets/icons/video-white.svg" alt="" v-if="store.darkMode" />
                      <img src="../../../assets/icons/video.svg" alt="" v-else />
                    </span>
                  </div>
                </div>
                <div v-if="stop.type === 'artwork'" class="tours__item__content">
                  <p class="tours__item__title">{{ stop.artwork.title }}</p>
                  <p class="tours__item__artist">{{ getCreators(stop.artwork.creators) }}</p>
                  <div class="tours__item__bottom">
                    <div class="tours__item__info">
                      <p class="tours__item__detail">{{ $t('app.id.prefix') }} {{ stop.artwork.appId }}</p>
                      <p class="tours__item__detail">{{ stop.artwork.location }}</p>
                    </div>
                    <div class="tours__item__icons">
                      <span v-if="stop.artwork.audio || stop.audio">
                        <img src="../../../assets/icons/audio-white.svg" alt="" v-if="store.darkMode" />
                        <img src="../../../assets/icons/audio.svg" alt="" v-else />
                      </span>
                      <span v-if="stop.artwork.video">
                        <img src="../../../assets/icons/video-white.svg" alt="" v-if="store.darkMode" />
                        <img src="../../../assets/icons/video.svg" alt="" v-else />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
    <ContentLoader v-else-if="!error" />
    <ErrorScreen v-else :error="error" />
    <Actionbar @addNoScroll="(val) => (overlayVisible = val)" />
  </div>
</template>

<script src="./Tours.js"></script>
