import { defineStore } from 'pinia';
import { debounce } from 'lodash';
import Api from '../api';
import TourModel from '../models/TourModel';

const APIS = {
  'bezoek.mleuven.be': 'https://www.mleuven.be',
  'visit.mleuven.be': 'https://www.mleuven.be',
  'visitez.mleuven.be': 'https://www.mleuven.be',
  'bezoek.mleuven.be.dev2.minsky.be': 'https://www.mleuven.be.dev2.minsky.be',
  'visit.mleuven.be.dev2.minsky.be': 'https://www.mleuven.be.dev2.minsky.be',
  'visitez.mleuven.be.dev2.minsky.be': 'https://www.mleuven.be.dev2.minsky.be',
};

const mainStore = defineStore('mainStore', {
  state: () => ({
    onboarded: false,
    darkMode: false,
    letterSize: 0,
    allowZoom: false,
    loading: false,
    entryRoute: null,
    promptInstall: false,
    installCancelled: false,
    deferredPrompt: null,
    customHistory: {
      tours: {},
    },
    audioData: {
      image: {},
      audio: {},
    },
    audioPlayer: null,
    audioDuration: 0,
    // language
    language: null,
    // api
    api: new Api({ baseUrl: APIS[window.location.host] ? APIS[window.location.host] : 'https://www.mleuven.be.dev2.minsky.be' }),
    // data sets
    settings: null,
    tours: {},
    expos: {},
    artworks: {},
    artworkUUIDs: {},
    // errors
    errors: {
      tours: {},
      expos: {},
      artworks: {},
    },
  }),

  getters: {
    isOnboarded() {
      return this.onboarded;
    },
  },

  actions: {
    setOnboarded(boolean) {
      this.onboarded = boolean;
    },
    setLetterSize(size) {
      this.letterSize = size;
    },
    toggleDarkMode(boolean) {
      this.darkMode = boolean;
    },
    toggleZoom(boolean) {
      this.allowZoom = boolean;
      let value = ', user-scalable=yes';

      if (!boolean) {
        value = ', user-scalable=no';
      }
      document.body.classList.toggle('allow-zoom', boolean);
      document.querySelector('meta[name="viewport"]').setAttribute('content', `width=device-width, initial-scale=1.0, maximum-scale=6.0${value}`);
    },
    setLanguage(lang) {
      if (lang !== document.documentElement.lang) {
        this.resetData();
        document.documentElement.setAttribute('lang', lang);
      }
      this.language = lang;
    },
    setEntryRoute(route) {
      this.entryRoute = route;
    },
    cancelInstall() {
      this.promptInstall = false;
      this.installCancelled = true;
    },
    showInstallPrompt() {
      this.promptInstall = true;
    },
    setDefferedPrompt(e) {
      this.deferredPrompt = e;
    },
    setHistory(key, uuid, from) {
      this.customHistory[key][uuid] = from;
    },
    resetData() {
      this.settings = null;
      this.tours = {};
      this.expos = {};
      this.artworks = {};
    },
    setAudioPlayer(player) {
      this.audioPlayer = player;
    },
    // Method to call the debounced function
    playAudio(data) {
      this.audioData = data;
      this.playAudioDebounced();
    },
    // Debounced action to play audio
    playAudioDebounced: debounce(function () {
      if (this.audioPlayer) {
        this.audioPlayer.load();
        this.audioPlayer.play();
      }
    }, 300),
    stopAudio() {
      this.audioPlayer.pause();
      this.audioData = {
        image: {},
        audio: {},
      };
    },
    setAudioDuration() {
      this.audioDuration = this.audioPlayer.duration;
    },
    play() {
      this.audioPlayer.play();
    },
    pause() {
      this.audioPlayer.pause();
    },
    // async data handling
    async getSettings() {
      return this.api
        .fetchData('settings', this.language)
        .then((r) => r.json())
        .then((settings) => {
          this.settings = settings;
          this.loading = false;
        });
    },
    async getTour(uuid, stopIndex = 1) {
      // create new TourModel
      this.tours[uuid] = new TourModel({ lang: this.language, api: this.api, uuid });
      // fetch tours
      const tourFetch = this.api
        .fetchData('tour', this.language, { uuid })
        .then((r) => r.json())
        .then((tour) => {
          this.tours[uuid].tour = tour;
        })
        .catch((error) => {
          this.errors.tours[uuid] = error.message;
        });

      const stopsFetch = this.getStops(uuid, stopIndex).catch((error) => {
        console.error(error.message);
      });

      return Promise.all([tourFetch, stopsFetch]);
    },
    async getStops(tourId, stopIndex) {
      return this.tours[tourId].getStops(stopIndex);
    },
    async getExpos(uuid) {
      return this.api
        .fetchData('expo', this.language, { uuid })
        .then((r) => r.json())
        .then((expo) => {
          this.expos[uuid] = expo;
        })
        .catch((error) => {
          this.errors.expos[uuid] = error.message;
        });
    },
    async getArtworks(uuid) {
      return this.api
        .fetchData('artwork', this.language, { uuid })
        .then((r) => r.json())
        .then((artwork) => {
          this.artworks[uuid] = artwork;
        })
        .catch((error) => {
          this.errors.artworks[uuid] = error.message;
        });
    },

    async getUUIDfromId(id) {
      return this.api
        .fetchData('artworkUUID', this.language, { id })
        .then((r) => r.json())
        .then((artwork) => {
          this.artworkUUIDs[id] = artwork.uuid;
        });
    },
  },

  persist: {
    paths: ['onboarded', 'language', 'darkMode', 'letterSize', 'allowZoom', 'promptInstall', 'installCancelled'],
  },
});

export default mainStore;
