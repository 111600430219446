import mainStore from '../../stores/mainStore';

export default {
  props: {
    allowBack: {
      type: Boolean,
      default: true,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    chevronIcon: {
      type: Boolean,
      default: false,
    },
    customRoute: {
      type: Object,
    },
  },

  components: {},

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  computed: {},

  emits: ['close'],

  methods: {
    goBack() {
      if (this.allowBack) {
        if (!window.history.state.back) {
          this.$router.push({ name: 'home' });
        }
        if (!this.customRoute) {
          this.$router.go(-1); // één stap terug
        } else {
          this.$router.push(this.customRoute);
        }
      } else {
        this.$emit('close');
      }
    },
  },

  watch: {},
};
