export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
    },
  },

  components: {},

  mounted() {},

  data() {
    return {
      windowHeight: `${window.innerHeight}px`,
    };
  },

  computed: {},

  methods: {},

  watch: {},
};
