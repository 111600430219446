import mainStore from '../../stores/mainStore';

export default {
  props: {},

  components: {},

  mounted() {
    console.log('[Scan mounted] Scan component is loading...');
  },

  data() {
    const store = mainStore();

    // Remove dev domains on deploy
    const allowedDomains = ['https://bezoek.mleuven.be', 'https://visit.mleuven.be', 'https://visitez.mleuven.be', 'https://bezoek.mleuven.be.dev2.minsky.be', 'https://bezoek.mleuven.be.web3.minsky.be', 'https://qrco.de'];

    return {
      store,
      allowedDomains,
      loading: false,
      domainError: false,
      error: '',
      cameraError: '',
      scanId: '',
      timer: null,
      showNotice: false,
      cameraLoaded: false,
    };
  },

  computed: {
    uuid: {
      get() {
        return this.store.artworkUUIDs[this.scanId] ? this.store.artworkUUIDs[this.scanId] : null;
      },
    },
  },

  emits: ['closeModal'],

  methods: {
    close() {
      console.log('[Scan close] Closing modal, scan is getting destoryed');
      this.$emit('closeModal', 'scan');
    },

    async process(val = null) {
      this.loading = true;
      let res = null;
      this.showNotice = true;

      if (this.uuid) {
        this.close();
        this.$router.push({ name: 'artwork', params: { id: this.uuid } });
        // Force close, on artwork pages, there is no redirect otherwise
        return;
      }

      try {
        res = await this.store.getUUIDfromId(val || this.scanId);
      } catch (error) {
        this.error = true;
        this.showNotice = true;
      }

      this.loading = false;

      if (!res) {
        this.error = true;
      }

      if (this.uuid) {
        this.close();
        this.$router.push({ name: 'artwork', params: { id: this.uuid } });
        // Force close, on artwork pages, there is no redirect otherwise
      }
    },

    async onDetect(val) {
      console.log('[Scan succes] Scanned value:', val);
      if (val[0] && val[0].rawValue) {
        console.log('[Scan succes] QR Code has url:', val[0].rawValue);

        const currentDomain = window.location.origin;
        const rawValueDomain = new URL(val[0].rawValue).origin;

        // Check if the raw value domain is in the allowed domains
        if (!this.allowedDomains.includes(rawValueDomain)) {
          console.log('[Scan succes] URL is not whitelisted');

          this.showNotice = true;
          this.domainError = true;
          return;
        }

        if (currentDomain !== rawValueDomain) {
          console.log('[Scan succes] URL is allowed, but on a different domain. Redirecting');
          window.location.href = val[0].rawValue;
        } else {
          console.log('[Scan succes] URL is allowed, and on the same domain. Redirecting');
          this.$router.push(val[0].rawValue);
        }
      }
    },

    async onError(error) {
      console.log('[Scan error] Error:', error);
      if (error.name === 'NotAllowedError') {
        this.cameraError = 'U moet toestemming voor cameratoegang verlenen';
      } else if (error.name === 'NotFoundError') {
        this.cameraError = 'Geen camera op dit apparaat';
      } else if (error.name === 'NotSupportedError') {
        this.cameraError = 'Veilige context vereist (HTTPS, localhost)';
      } else if (error.name === 'NotReadableError') {
        this.cameraError = 'Is de camera al in gebruik?';
      } else if (error.name === 'OverconstrainedError') {
        this.cameraError = "Geïnstalleerde camera's zijn niet geschikt";
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.cameraError = 'Stream API wordt niet ondersteund in deze browser';
      }
    },

    async onInputArtwork() {
      await this.process();
    },

    async onReady() {
      console.log('[Scan initialzing] Camera is ready');
      this.cameraLoaded = true;
    },
  },
  watch: {},
  created() {
    this.$watch('showNotice', (val) => {
      if (val) {
        this.timer = setTimeout(() => {
          this.showNotice = false;
          this.domainError = false;
          this.error = false;
        }, 5000);
      } else {
        clearTimeout(this.timer);
      }
    });
  },
};
