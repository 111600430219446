export default {
  props: {},

  components: {},

  mounted() {},

  data() {
    return {};
  },

  computed: {},

  methods: {},

  watch: {},
};
