<template>
  <div class="exit-tour">
    <div class="exit-tour__container">
      <div class="exit-tour__close">
        <img src="../../assets/icons/close-white.svg" alt="" @click="closeOverlay('exitTour')" v-if="store.darkMode" />
        <img src="../../assets/icons/close.svg" alt="" @click="closeOverlay('exitTour')" v-else />
      </div>
      <div class="exit-tour__body">
        <p class="exit-tour__title">{{ $t('tour.overlay.ending.title') }}</p>
        <p class="exit-tour__paragraph">{{ $t('tour.overlay.ending.paragraph') }}</p>

        <button class="button__icon button__arrow-right button__white button__full-width" @click="closeTour">
          <span>{{ $t('tour.overlay.ending.yes') }}</span>
          <img src="../../assets/icons/arrow-right-white.svg" alt="" v-if="store.darkMode" />
          <img src="../../assets/icons/arrow-right.svg" alt="" v-else />
        </button>
        <button class="button__icon button__arrow-right button__full-width" @click="closeOverlay('all')">
          <span>{{ $t('tour.overlay.ending.no') }}</span>
          <img src="../../assets/icons/arrow-right.svg" alt="" v-if="store.darkMode" />
          <img src="../../assets/icons/arrow-right-white.svg" alt="" v-else />
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./ExitTourOverlay.js"></script>
