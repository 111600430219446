import mainStore from '../../stores/mainStore';
import Info from '../Info/Info.vue';
import Scan from '../Scan/Scan.vue';

export default {
  props: {
    homeActive: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Info,
    Scan,
  },

  mounted() {},

  data() {
    const store = mainStore();

    return {
      store,
      showInfo: false,
      showScan: false,
    };
  },

  computed: {},

  emits: ['addNoScroll'],

  methods: {
    openHome() {
      this.showInfo = false;
      this.$router.push({ name: 'home' });
    },
    openInfo() {
      this.showInfo = !this.showInfo;
      this.showScan = false;
    },
    openScan() {
      this.showInfo = false;
      this.showScan = true;
    },
    closeInfo() {
      this.showInfo = false;
    },
  },

  watch: {
    showInfo(val) {
      this.$emit('addNoScroll', val);
    },
    showScan(val) {
      this.$emit('addNoScroll', val);
    },
  },
};
