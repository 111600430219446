import mainStore from '../../../stores/mainStore';
import BackButton from '../../BackButton/BackButton.vue';
import OptionsList from '../../OptionsList/OptionsList.vue';

export default {
  props: {},

  components: {
    BackButton,
    OptionsList,
  },

  mounted() {},

  data() {
    const store = mainStore();

    return {
      store,
      accessibilityOptions: [
        // { text: this.$t('accessibility.option1'), hasToggle: true, value: false },
        { text: this.$t('accessibility.option2'), hasToggle: false, value: store.letterSize },
        { text: this.$t('accessibility.option3'), hasToggle: true, value: store.darkMode },
        { text: this.$t('accessibility.option4'), hasToggle: true, value: store.allowZoom },
      ],
    };
  },

  computed: {
    accessibility: {
      get() {
        return this.store.settings.accessibilityCopy;
      },
    },
  },

  methods: {},

  watch: {
    accessibilityOptions: {
      deep: true,
      immediate: true,
      handler(val) {
        this.store.toggleDarkMode(val[1].value);
        this.store.toggleZoom(val[2].value);
      },
    },
  },
};
