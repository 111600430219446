import mainStore from '../../stores/mainStore';

export default {
  props: {
    subtitle: {
      type: String,
    },
    tourId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
    },
  },

  components: {},

  mounted() {
    if (!this.teasers) {
      this.tourModel.getStopTeasers().then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      loading: true,
    };
  },

  computed: {
    tourModel: {
      get() {
        return this.store.tours[this.tourId] ? this.store.tours[this.tourId] : null;
      },
    },
    teasers: {
      get() {
        return this.tourModel.stopTeasers ? this.tourModel.stopTeasers : null;
      },
    },
  },

  emits: ['closeModal', 'toStep'],

  methods: {
    closeModal() {
      this.$emit('closeModal', 'tourOverview');
    },
    onStepClick() {
      this.closeModal();
    },
    getCreators(creators) {
      const names = creators.map((c) => c.name);
      return names.join(', ');
    },
  },

  watch: {},
};
