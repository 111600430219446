<template>
  <div class="onboarding-step">
    <div class="onboarding-step__inner">
      <div class="onboarding-step__image" :style="{ background: 'linear-gradient(0deg, rgba(13, 13, 13, 0.30) 0%, rgba(13, 13, 13, 0.30) 100%), url(' + step.image + ') lightgray 50% / cover no-repeat' }"></div>
      <div class="onboarding-step__content">
        <h2 class="title">{{ step.title }}</h2>
        <p class="onboarding-step__text" v-html="step.text"></p>
        <RouterLink v-if="index === '0'" class="button__link onboarding-step__link" :to="{ name: 'accessibility' }">{{ $t('onboarding.step1.link') }}</RouterLink>
      </div>
    </div>
  </div>
</template>

<script src="./OnboardingStep.js"></script>
