<template>
  <div class="onboarding">
    <div class="onboarding__inner" ref="onboardingInnerRef" :class="{ 'add-shadow': addShadow }">
      <button class="button__skip onboarding__skip" @click="onSkipClick">{{ $t('onboarding.skip') }}</button>
      <div :class="{ 'slide-direction__left': isPrevious, onboarding__steps: true }">
        <TransitionGroup name="slide" tag="div" @after-enter="onStepChange">
          <OnboardingStep v-drag="dragHandler" :step="steps[currentStep]" :index="`${currentStep}`" :key="currentStep" class="onboarding__step" />
        </TransitionGroup>
      </div>
      <div class="onboarding__actions">
        <Pagination class="onboarding__pagination" :steps="steps" :currentStep="currentStep" @update:currentStep="setCurrentStep" />
        <button class="button" @click="nextStep">{{ isLastStep ? $t('onboarding.start') : $t('onboarding.next') }}</button>
      </div>
    </div>
  </div>
</template>

<script src="./Onboarding.js"></script>
