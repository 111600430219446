<template>
  <div class="newsletter">
    <div class="newsletter__back">
      <BackButton :allowBack="false" @close="close" />
    </div>
    <div class="newsletter__header">
      <h2 class="title">{{ newsletter.title }}</h2>
      <div v-html="newsletter.intro"></div>
    </div>
    <div class="newsletter__form">
      <div id="mc_embed_signup">
        <form action="https://mleuven.us7.list-manage.com/subscribe/post?u=a24298650423c38fe90dbcaa2&amp;id=63f7f3961b&amp;f_id=00efc2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate form form--mailchimp-newsletter" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll" class="form__inner">
            <div class="indicates-required"><span class="asterisk">*</span> {{ $t('info.newsletter.required') }}</div>
            <div class="mc-field-group form-item form-item--text form-item--email">
              <label for="mce-EMAIL">{{ $t('info.newsletter.email') }} <span class="asterisk">*</span></label>
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" />
            </div>
            <div class="mc-field-group form-item form-item--text form-item--first-name">
              <label for="mce-FNAME">{{ $t('info.newsletter.firstName') }} <span class="asterisk">*</span></label>
              <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" />
            </div>
            <div class="mc-field-group form-item form-item--text form-item--last-name">
              <label for="mce-LNAME">{{ $t('info.newsletter.surname') }} <span class="asterisk">*</span></label>
              <input type="text" value="" name="LNAME" class="required" id="mce-LNAME" />
            </div>
            <div class="mc-field-group input-group form-item form-hide">
              <strong class="form__strong-title">{{ $t('info.newsletter.language.label') }} </strong>
              <ul>
                <li>
                  <input type="radio" value="Nederlands" name="MMERGE9" id="mce-MMERGE9-0" /><label for="mce-MMERGE9-0">{{ $t('info.newsletter.language') }}</label>
                </li>
              </ul>
            </div>

            <Accordion :data="accordionData" />

            <div class="mc-field-group input-group form-item form-item--checkbox form-item--privacy">
              <strong class="form__strong-title">{{ $t('info.newsletter.privacy.title') }}</strong>
              <ul>
                <li><input type="checkbox" value="549755813888" name="group[12120][549755813888]" id="mce-group[12120]-12120-0" /><label for="mce-group[12120]-12120-0" v-html="$t('info.newsletter.privacy.input')"></label></li>
              </ul>
            </div>
            <div class="mc-field-group" style="display: none">
              <input type="hidden" name="tags" value="35044" />
              <label for="mce-MMERGE3">Signnup source </label>
              <input type="text" value="M LEUVEN" name="MMERGE3" class="" id="mce-MMERGE3" />
              <span id="mce-MMERGE3-HELPERTEXT" class="helper_text"></span>
            </div>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style="display: none"></div>
              <div class="response" id="mce-success-response" style="display: none"></div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px" aria-hidden="true"><input type="text" name="b_a24298650423c38fe90dbcaa2_63f7f3961b" tabindex="-1" value="" /></div>
            <div class="clear form-actions"><input type="submit" :value="$t('info.newsletter.submit')" name="subscribe" id="mc-embedded-subscribe" class="button button__light" /></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script src="./Newsletter.js"></script>
