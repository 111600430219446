<template>
  <div class="backButton">
    <div class="backButton__inner">
      <a class="button__back" :class="isWhite ? 'button__back__white' : ''" href="#" @click="goBack()">
        <img src="./../../assets/icons/chevron.svg" alt="" v-if="chevronIcon" />
        <img src="./../../assets/icons/arrow-left-white.svg" alt="Left arrow" v-else-if="store.darkMode" />
        <img src="./../../assets/icons/arrow-left.svg" alt="Left arrow" v-else-if="!isWhite" />
        <img src="./../../assets/icons/arrow-left-white.svg" alt="Left arrow" v-else />
        <span>{{ $t('button.back') }}</span>
      </a>
    </div>
  </div>
</template>

<script src="./BackButton.js"></script>
