<template>
  <div class="cover lightbox-image" :class="{ cover__action: isAction, cover__room: isRoom, 'cover--height-auto': autoHeight }">
    <div class="cover__image" v-if="!isBackground">
      <img :src="image.urlNormal" :alt="image.alt" />
    </div>
    <div class="cover__image cover__image__background" v-else :style="{ background: 'linear-gradient(0deg, rgba(13, 13, 13, 0.30) 0%, rgba(13, 13, 13, 0.30) 100%), url(' + image.urlNormal + ') lightgray 50% / cover no-repeat' }"></div>

    <a data-fslightbox class="cover__modal__button" :href="image.urlLarge" :data-caption="info ? info : ''">
      <img src="../../assets/icons/modal.svg" alt="" />
    </a>
    <div class="cover__info__button" v-if="info" @click="() => (showInfo = true)">
      <p>i</p>
    </div>

    <Transition name="cover">
      <div class="cover__info" v-if="showInfo">
        <div class="cover__info-wrapper" v-html="info" />
        <img src="../../assets/icons/close-white.svg" alt="" @click="() => (showInfo = false)" />
      </div>
    </Transition>
  </div>
</template>

<script src="./Cover.js"></script>
