class TourModel {
  constructor(args) {
    this.tour = args.tour || null;
    this.lang = args.lang || null;
    this.api = args.api || null;
    this.uuid = args.uuid;
    this.limit = args.limit || 5;

    this.stops = {};
    this.stopTeasers = null;

    this.page = 0;

    this.promises = {};
  }

  async getStops(index) {
    const page = Math.ceil(index / this.limit) - 1;
    if (!this.promises[page]) {
      this.promises[page] = this.api
        .fetchData('stops', this.lang, { uuid: this.uuid }, { page, limit: this.limit })
        .then((r) => r.json())
        .then((stops) => {
          for (let i = 0; i < stops.data.length; i += 1) {
            const stop = stops.data[i];
            if (page > 0) {
              this.stops[page * this.limit + (i + 1)] = stop;
            } else {
              this.stops[i + 1] = stop;
            }
          }
        });
    }
    return this.promises[page];
  }

  async getStopTeasers() {
    return this.api
      .fetchData('stops', this.lang, { uuid: this.uuid }, { format: 'teaser' })
      .then((r) => r.json())
      .then((stops) => {
        this.stopTeasers = stops;
      });
  }
}

export default TourModel;
