export default {
  // ONBOARDING
  'onboarding.skip': 'Skip',
  'onboarding.start': 'Start',
  'onboarding.next': 'Next',
  // 'onboarding.step1.title': 'Welcome to M',
  // 'onboarding.step1.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'onboarding.step1.link': 'Accessibility settings',
  // 'onboarding.step2.title': 'Scan or search',
  // 'onboarding.step2.text': 'Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.',
  // 'onboarding.step3.title': 'Listen and read',
  // 'onboarding.step3.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',

  // HOME
  // 'home.title': 'Featured',
  // 'home.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'home.today.title': 'Today in M',
  // 'home.today.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'home.extra.title': 'Also in M',
  // 'home.extra.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',

  // SETTINGS
  // 'settings.title': 'Settings',
  // 'settings.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'settings.language': 'Language',
  'settings.accessibility': 'Accessibility',

  // LANGUAGES
  'languages.title': 'Change language',
  'languages.select-title': 'Select your language',

  //   Offline
  'offline.title': 'Your internet connection seems to be unstable.',
  'offline.text1': 'Luckily, we have free Wi-Fi in the museum!',
  'offline.text2': 'Go to your cellphone settings and select "M-Publiek" in the Wi-Fi menu.',

  // ACCESSIBILITY
  // 'accessibility.title': 'Accessibility',
  // 'accessibility.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'accessibility.option1': 'Reading function',
  'accessibility.option2': 'Text size',
  'accessibility.option3': 'Dark theme',
  'accessibility.option4': 'Allow zoom',

  // GENERAL
  'app.id.prefix': 'Number',
  'button.back': 'Back',
  'actionbar.home': 'Home',
  'actionbar.scan': 'Scan or search',
  'actionbar.info': 'Info',

  // INFO
  // 'info.title': 'Info',
  // 'info.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'info.floorplan': 'View floor plan',
  'info.onboarding': 'How to use the app?',
  'info.newsletter': 'Stay informed',
  'info.newsletter.required': 'Required',
  'info.newsletter.email': 'E-mail',
  'info.newsletter.firstName': 'First name',
  'info.newsletter.surname': 'Last name',
  'info.newsletter.language.label': 'Language',
  'info.newsletter.language': 'English',
  'info.newsletter.emails.label': 'I would like to receive',
  'info.newsletter.emails.monthly': "M' s monthly newsletter where I can read about what's happening in M",
  'info.newsletter.emails.fund': "information about M's museum fund, M-LIFE",
  'info.newsletter.emails.midzomer': 'information about the culture and music festival, M-IDZOMER',
  'info.newsletter.emails.zomervanm': 'all about the activities and programme of the Summer of M',
  'info.newsletter.emails.b2b': 'the quarterly B2B news about the corporate hospitality offerings, nocturnes, teambuildings, ...',
  'info.newsletter.emails.family': 'all about the family activities and programme of the Summer of M',
  'info.newsletter.emails.magazine': 'the digital Magazine of M (twice a year)',
  'info.newsletter.emails.free': 'information on the free offer of lectures, classes, debates and meetings; Denkvoer',
  'info.newsletter.emails.playground': "information on Playground, M and STUK's live arts festival",
  'info.newsletter.educational': "The education newsletter with M's offers for",
  'info.newsletter.educational.preschool': 'nursery school children',
  'info.newsletter.educational.primary': 'primary education',
  'info.newsletter.educational.secondary': 'secondary education',
  'info.newsletter.educational.university': 'higher education',
  'info.newsletter.educational.adult': 'adult education',
  'info.newsletter.educational.artschool': 'part-time art education',
  'info.newsletter.privacy.title': 'Privacy Statement + Conditions',
  'info.newsletter.privacy.input': 'I have read and agreed to M\'s <a href="https://www.mleuven.be/nl/privacybeleid" target="_blank">privacy statement</a>.',
  'info.newsletter.submit': 'I am registering',
  'info.about': 'About M Leuven',

  // INFO DETAIL
  // 'floorplan.title': 'Floor plan',
  // 'floorplan.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'about.title': 'About M Leuven',
  // 'about.text': '<p>The museum showcases old and new art inspired by the versatility of Leuven. The collection mainly focuses on art production in Leuven and Brabant from the Middle Ages to the 19th century, including Constantin Meunier, Jef Lambeaux, and Georges Minne.</p><p>In addition to the permanent collection, M presents temporary exhibitions of both old masters and contemporary artists.</p>',
  // 'about.button': 'Go to the website',
  // 'newsletter.title': 'Stay informed about what’s happening at M',
  // 'newsletter.text': 'Sign up below for one of the newsletters and we’ll make sure you get your monthly dose of M news.',
  // 'visit.title': 'After your visit',
  // 'visit.subtitle1': 'Discover Barbóék at M',
  // 'visit.paragraph1': 'You can buy museum souvenirs, as well as art books and publications related to M and the exhibitions at M. And of course, it’s the perfect place to chat about your visit to M over a cup of coffee and a piece of cake.',
  // 'visit.subtitle2': 'Discover Barbóék at M',
  // 'visit.paragraph2': 'You can buy museum souvenirs, as well as art books and publications related to M and the exhibitions at M. And of course, it’s the perfect place to chat about your visit to M over a cup of coffee and a piece of cake.',

  // SCAN
  'scan.title': 'Scan or search',
  'scan.label': 'Or enter artwork number',
  'scan.placeholder': 'Artwork number',
  'scan.loading': 'Searching...',
  'scan.error': 'No artwork found',
  'scan.loadingCamera': 'Loading...',
  'scan.errorOther': 'Something went wrong, please try again',

  // EXPO
  'expo.label': 'Exhibition',
  'expo.tours': 'Tours',
  'expo.artworks': 'Artworks in this exhibition',
  'expo.activities': 'Activities at M',

  // ARTWORK
  'artwork.info': 'More information about work',
  'artwork.video': 'Watch video',
  'artwork.specifications': 'View specifications',

  // AUDIOPLAYER
  'audioplayer.transcriptShow': 'Show text',
  'audioplayer.transcriptHide': 'Close text',

  // TOUR
  'tour.start': 'Start tour',
  'tour.content': 'In this tour',
  'tour.stop': 'Stop tour',
  // 'tour.end': 'End the tour',
  'tour.to': 'Go to',
  // 'tour.ending': 'This is the end of the tour',
  'tour.expected': 'Expected activities',
  'tour.newsletter': 'Stay informed',
  'tour.afterwards': 'After your visit',
  'tour.home': 'Back to home',
  'tour.overlay.ending': 'Stop tour',
  'tour.overlay.ending.title': 'Are you sure you want to stop this tour',
  'tour.overlay.ending.paragraph': '',
  'tour.overlay.ending.yes': 'Yes, I want to stop the tour?',
  'tour.overlay.ending.no': 'No, I am not done yet',
  'tour.overlay.floorplan': 'Floor plan',
  'tour.overlay.overview': 'Tour overview',
  'tour.overlay.scan': 'Scan or search',
  'tour.overlay.settings': 'Settings',

  // INSTALL
  'download.prompt': 'Download the app',
  'install.title': 'Install the app!',
  'install.text': 'You can now install this application on your device for easier access and a better experience. Click the button below to start the installation.',
  'install.cta': 'Install',

  // specs
  'specs.header': 'Technical information',
  'specs.identification': 'Identification',
  'specs.title': 'Title',
  'specs.objectNames': 'Object name',
  'specs.location': 'Location',
  'specs.creation': 'Maker',
  'specs.creators': 'Maker(s)',
  'specs.period': 'Period',
  'specs.date': 'Date',
  'specs.date.from': 'from',
  'specs.date.to': 'to',
  'specs.technical': 'Materials & Techniques',
  'specs.materials': 'Material',
  'specs.techs': 'Technique',
  'specs.dimensions': 'Dimensions',

  // errors
  'error.404': "Unfortunately this page doesn't exist (anymore)",
  'error.500': 'Unfortunately something went wrong retrieving the correct data.',

  'error.general': 'Something went wrong',
  'error.notfound': 'Page not found',
  'error.home': 'Back to home',
};
