<template>
  <div class="install-prompt">
    <div class="install-prompt__inner">
      <button class="install-prompt__close">
        <img src="../../assets/icons/close-white.svg" alt="" @click="onCloseClick" v-if="store.darkMode" />
        <img src="../../assets/icons/close.svg" alt="" @click="onCloseClick" v-else />
      </button>
      <h3>{{ $t('install.title') }}</h3>
      <p>{{ $t('install.text') }}</p>
      <button @click="onInstallClick" class="button">{{ $t('install.cta') }}</button>
    </div>
  </div>
</template>

<script src="./InstallPrompt.js"></script>
