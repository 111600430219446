import BackButton from '../BackButton/BackButton.vue';
import mainStore from '../../stores/mainStore';

export default {
  props: {
    isFullScreen: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    BackButton,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
      activeFloor: 0,
    };
  },

  computed: {
    floorplans: {
      get() {
        return this.store.settings.floorplan;
      },
    },
  },

  emits: ['closeOverlay'],

  methods: {
    close() {
      this.$emit('closeOverlay', 'floorplan');
    },
  },

  watch: {},
};
