import mainStore from '../../../stores/mainStore';
import BackButton from '../../BackButton/BackButton.vue';
import DownloadPrompt from '../../DownloadPrompt/DownloadPrompt.vue';

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BackButton,
    DownloadPrompt,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
      showLang: true,
      version: __APP_VERSION__,
    };
  },

  computed: {
    settings: {
      get() {
        return this.store.settings.settingsCopy;
      },
    },
    hasDeferredPrompt: {
      get() {
        return !!this.store.deferredPrompt;
      },
    },
  },

  emits: ['closeModal'],

  methods: {
    close() {
      this.$emit('closeModal', 'settings');
    },
  },

  watch: {},
};
