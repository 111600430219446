<template>
  <div class="scan">
    <div class="scan__top">
      <p class="scan__title">{{ $t('scan.title') }}</p>
      <img src="../../assets/icons/close-white.svg" alt="" @click="close" />
    </div>
    <div class="scan__middle">
      <qrcode-stream @error="onError" @detect="onDetect" @camera-on="onReady">
        <svg v-if="cameraLoaded" class="scan__icon" viewBox="0 0 270 270" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.00012207" width="8.63999" height="53.9999" fill="white" />
          <rect x="0.000244141" y="8.64026" width="8.63999" height="53.9999" transform="rotate(-90 0.000244141 8.64026)" fill="white" />
          <rect x="0.000244141" y="270" width="8.63999" height="53.9999" transform="rotate(-90 0.000244141 270)" fill="white" />
          <rect x="8.64038" y="270" width="8.63999" height="53.9999" transform="rotate(180 8.64038 270)" fill="white" />
          <rect x="270" y="0.00012207" width="8.63999" height="53.9999" transform="rotate(90 270 0.00012207)" fill="white" />
          <rect x="261.36" y="0.000366211" width="8.63999" height="53.9999" fill="white" />
          <rect x="270" y="270" width="8.63999" height="53.9999" transform="rotate(-180 270 270)" fill="white" />
          <rect x="270" y="261.36" width="8.63999" height="53.9999" transform="rotate(90 270 261.36)" fill="white" />
        </svg>
        <div v-else class="scan__loading">
          <svg id="L9" version="1.1" class="icon-svg icon-svg--loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path fill="currentColor" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
              <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
            </path>
          </svg>
        </div>
      </qrcode-stream>
    </div>
    <div class="scan__bottom">
      <label for="artwork" class="scan__label">— {{ $t('scan.label') }} —</label>

      <div class="scan__input__wrapper">
        <input :disabled="this.loading" type="text" inputmode="numeric" pattern="[0-9]*" maxlength="3" class="scan__input" id="artwork" name="artwork" v-model="scanId" :placeholder="$t('scan.placeholder')" @keyup.enter="onInputArtwork" />
        <button class="scan__input__submit" type="submit" @click="onInputArtwork"><img src="../../assets/icons/search.svg" alt="" /></button>
      </div>
      <Transition name="show">
        <div class="scan__notice-wrapper" v-if="showNotice">
          <p class="scan__notice" v-if="loading">{{ $t('scan.loading') }}</p>
          <p class="scan__notice" v-if="error && !loading">{{ $t('scan.error') }}</p>
          <p class="scan__notice" v-if="domainError && !loading">{{ $t('scan.errorOther') }}</p>
          <p class="scan__notice" v-if="cameraError && !loading">{{ cameraError }}</p>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script src="./Scan.js"></script>
