<template>
  <div class="overview-tour">
    <div class="overview-tour__back">
      <img src="../../assets/icons/close-white.svg" alt="" @click="closeModal" v-if="store.darkMode" />
      <img src="../../assets/icons/close.svg" alt="" @click="closeModal" v-else />
    </div>
    <div class="overview-tour__details">
      <p class="title">{{ $t('tour.overlay.overview') }}</p>
      <p class="overview-tour__subtitle">{{ subtitle }}</p>
    </div>

    <div class="overview-tour__container">
      <div v-if="!loading" class="overview-tour__list">
        <RouterLink @click="onStepClick" v-for="(stop, i) in teasers" :to="{ name: 'tour-stop', params: { tourId: tourId, index: i + 1 } }" :key="stop.uuid">
          <div class="overview-tour__list-item" :class="{ 'overview-tour__list-item__active': i === index - 1 }" v-if="!stop.isAction">
            <div class="overview-tour__item__image" v-if="stop.type === 'default'">
              <img :src="stop.image" alt="" />
            </div>
            <div class="overview-tour__item__image" v-if="stop.type === 'artwork'">
              <img :src="stop.artwork.image" alt="" />
            </div>
            <div v-if="stop.type === 'default'" class="overview-tour__item__content overview-tour__item__content__default">
              <p class="overview-tour__item__title">{{ stop.title }}</p>
              <div class="overview-tour__item__icons">
                <span v-if="stop.audio">
                  <img src="../../assets/icons/audio-white.svg" alt="" v-if="store.darkMode || i === index - 1" />
                  <img src="../../assets/icons/audio.svg" alt="" v-else />
                </span>
                <span v-if="stop.video">
                  <img src="../../assets/icons/video-white.svg" alt="" v-if="store.darkMode || i === index - 1" />
                  <img src="../../assets/icons/video.svg" alt="" v-else />
                </span>
              </div>
            </div>
            <div v-if="stop.type === 'artwork'" class="overview-tour__item__content">
              <p class="overview-tour__item__title">{{ stop.artwork.title }}</p>
              <p class="overview-tour__item__artist">{{ getCreators(stop.artwork.creators) }}</p>
              <div class="overview-tour__item__bottom">
                <div class="overview-tour__item__info">
                  <p class="overview-tour__item__detail">{{ $t('app.id.prefix') }} {{ stop.artwork.appId }}</p>
                  <p class="overview-tour__item__detail">{{ stop.artwork.location }}</p>
                </div>
                <div class="overview-tour__item__icons">
                  <span v-if="stop.artwork.audio || stop.audio">
                    <img src="../../assets/icons/audio-white.svg" alt="" v-if="store.darkMode || i === index - 1" />
                    <img src="../../assets/icons/audio.svg" alt="" v-else />
                  </span>
                  <span v-if="stop.artwork.video">
                    <img src="../../assets/icons/video-white.svg" alt="" v-if="store.darkMode || i === index - 1" />
                    <img src="../../assets/icons/video.svg" alt="" v-else />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </RouterLink>
      </div>
      <div v-else class="overview-tour__loader">
        <svg id="L9" version="1.1" class="icon-svg icon-svg--loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
          <path fill="currentColor" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
          </path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script src="./OverviewTourOverlay.js"></script>
