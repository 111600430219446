<template>
  <div class="settings" :class="{ settings__modal: isModal }">
    <div class="settings__back">
      <BackButton :allowBack="!isModal" @close="close" />
    </div>
    <h2 class="title">{{ settings.title }}</h2>
    <div v-html="settings.text"></div>
    <div class="settings__list">
      <RouterLink to="/languages" class="settings__list-item" v-if="showLang">
        <p>{{ $t('settings.language') }}</p>
        <img src="./../../../assets/icons/arrow-right-white.svg" v-if="store.darkMode" alt="Right arrow" />
        <img src="./../../../assets/icons/arrow-right.svg" v-else alt="Right arrow" />
      </RouterLink>
      <RouterLink to="/accessibility" class="settings__list-item">
        <p>{{ $t('settings.accessibility') }}</p>
        <img src="./../../../assets/icons/arrow-right-white.svg" v-if="store.darkMode" alt="Right arrow" />
        <img src="./../../../assets/icons/arrow-right.svg" v-else alt="Right arrow" />
      </RouterLink>
      <div v-if="hasDeferredPrompt" class="settings__list-item">
        <DownloadPrompt />
      </div>
    </div>
    <span class="settings__version">v - {{ version }}</span>
  </div>
</template>

<script src="./Settings.js"></script>
