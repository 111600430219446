<template>
  <button class="download-prompt" @click="clickHandler">
    <span class="download-prompt__label">{{ $t('download.prompt') }}</span>
    <figure class="download-prompt__icon-wrapper" v-if="store.darkMode">
      <img class="download-prompt__icon" src="../../assets/icons/arrow-right-white.svg" alt="Right arrow" />
    </figure>
    <figure class="download-prompt__icon-wrapper" v-if="!store.darkMode">
      <img class="download-prompt__icon" src="../../assets/icons/arrow-right.svg" alt="Right arrow" />
    </figure>
  </button>
</template>

<script src="./DownloadPrompt.js"></script>
