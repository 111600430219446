import mainStore from '../../stores/mainStore';
import Cover from '../Cover/Cover.vue';

export default {
  props: {
    data: {
      type: Array,
    },
  },

  components: {
    Cover,
  },

  mounted() {},

  data() {
    const store = mainStore();

    return {
      store,
      showId: null,
    };
  },

  computed: {},

  methods: {
    openAccordion(id) {
      if (this.showId === id) {
        this.showId = null;
      } else {
        this.showId = id;
      }
    },
    extractYouTubeVideoID(url) {
      const regExp = /youtu\.be\/([a-zA-Z0-9_-]{11})/;
      const fullRegExp = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
      let match = null;
      if (url.match(regExp)) {
        match = url.match(regExp);
      } else {
        match = url.match(fullRegExp);
      }
      return match && match[1] ? match[1] : null;
    },
    openPlayer(media) {
      this.$emit('openAccordionAudio', media);
    },
  },

  watch: {},
};
