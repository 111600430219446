class Api {
  constructor(args) {
    this.baseUrl = args.baseUrl || `https://mleuven.be`;
    this.endpoints = {
      settings: '/app-api/v1/settings',
      tour: '/app-api/v1/tour/${uuid}',
      stops: '/app-api/v1/tour/${uuid}/stops',
      artwork: '/app-api/v1/artwork/${uuid}',
      expo: '/app-api/v1/exhibition/${uuid}',
      artworkUUID: '/app-api/v1/artwork/${id}',
    };
    this.bearer = null;
  }

  async fetchData(type, lang, tokens, params) {
    // check if bearer token is existant
    const urlParams = new URLSearchParams(window.location.search);
    this.bearer = urlParams.get('_bearer-token');

    // Check is the current session is a preview or not. (MLA-224)
    // if (this.bearer) {
    //   sessionStorage.setItem('hasBearer', true);
    // } else {
    //   sessionStorage.removeItem('hasBearer');
    // }

    // constuct the url by replacing the ${id} tokens if necessery
    let url = tokens ? replaceTokens(this.endpoints[type], tokens) : this.endpoints[type];
    // add params
    if (params) {
      const p = new URLSearchParams(params);
      url = `${url}?${p.toString()}`;
    }

    return this.bearer
      ? fetch(lang === 'nl' || !lang ? `${this.baseUrl}${url}` : `${this.baseUrl}/${lang}${url}`, { headers: { Authorization: `Bearer ${this.bearer}` } })
      : fetch(lang === 'nl' || !lang ? `${this.baseUrl}${url}` : `${this.baseUrl}/${lang}${url}`).then((r) => {
          if (r.status !== 200) {
            throw new Error(`error.${r.status}`);
          }
          return r;
        });
  }
}

function replaceTokens(str, obj) {
  // Regular expression to match tokens like ${token}
  const tokenRegex = /\${(.*?)}/g;

  // Replace tokens in the string with corresponding values from the object
  return str.replace(tokenRegex, (match, token) =>
    // If the token exists in the object, replace it with its value, otherwise keep it as is
    obj[token] !== undefined ? obj[token] : match
  );
}

export default Api;
