import _isFinite from 'lodash/isFinite';
import VueSlider from 'vue-3-slider-component';
import mainStore from '../../stores/mainStore';
import BackButton from '../BackButton/BackButton.vue';

export default {
  props: {
    inFooter: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BackButton,
    VueSlider,
  },

  mounted() {
    this.$nextTick(() => {
      const audio = this.$refs.audioplayer;

      // add audioplayer to mainstore
      this.store.setAudioPlayer(audio);

      // audio.addEventListener('loadedmetadata', () => {
      //   this.initSlider();
      // });

      audio.addEventListener('canplay', () => {
        this.audioLoaded = true;
      });

      // Wait for audio to begin play, then start playback listener function
      // this.$watch('playing', function () {
      //   if (this.playing) {
      //     // this.initSlider();
      //     audio.addEventListener('timeupdate', this.playbackListener);

      //     // prevent starting multiple listeners at the same time
      //     if (!this.listenerActive) {
      //       this.listenerActive = true;
      //     }
      //   }
      // });

      // Update current audio position when user drags progress slider
      this.$watch('playbackTime', function () {
        const diff = Math.abs(this.playbackTime - this.$refs.audioplayer.currentTime);

        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.$refs.audioplayer.currentTime = this.playbackTime;
        }
      });

      audio.addEventListener('play', () => {
        this.audioPaused = false;
      });

      audio.addEventListener('pause', () => {
        this.audioPaused = true;
      });

      // audio.addEventListener('timeupdate', this.playbackListener);
    });
  },

  data() {
    const store = mainStore();

    return {
      store,
      showPlayer: false,
      showTranscript: false,
      playing: false,
      playbackTime: 0,
      audioDuration: 0,
      audioLoaded: false,
      listenerActive: false,
      audioTiming: 0,
      audioPaused: null,
    };
  },

  computed: {
    elapsedTime() {
      if (this.playbackTime) {
        const seconds = this.playbackTime;
        return this.convertTime(seconds);
      }
      return '00:00';
    },
    audioProgress: {
      get() {
        const percent = (this.playbackTime * 100) / this.audioDuration;
        return percent;
      },
      set(val) {
        if (_isFinite(val)) {
          const audio = this.$refs.audioplayer;
          audio.currentTime = (val * this.audioDuration) / 100;
        }
      },
    },

    data: {
      get() {
        return this.store.audioData;
      },
    },
  },

  emits: ['addNoScroll', 'closeAudioPlayer'],

  methods: {
    close() {
      this.showPlayer = false;
      this.showTranscript = false;
    },

    play() {
      this.store.play();
    },

    pause() {
      this.store.pause();
    },

    onCloseClick() {
      this.$emit('closeAudioPlayer');
    },

    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      const minutes = (seconds % 3600) / 60;

      return [minutes, seconds % 60].map(format).join(':');
    },

    // Show the total duration of audio file
    totalTime() {
      // const audio = this.$refs.audioplayer;
      if (this.store.audioPlayer) {
        this.audioDuration = this.store.audioPlayer.duration;
        if (Number.isNaN(this.audioDuration)) {
          return '00:00';
        }
        return this.convertTime(this.audioDuration);
      }
      return '00:00';
    },

    playbackListener() {
      const audio = this.$refs.audioplayer;
      if (audio) {
        this.playbackTime = audio.currentTime;

        // Add listeners for audio pause and audio end events
        audio.addEventListener('ended', this.endListener);
        audio.addEventListener('pause', this.pauseListener);
      }
    },

    // Function to run when audio is paused by user
    pauseListener() {
      this.playing = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },

    // Function to run when audio play reaches the end of file
    endListener() {
      this.playing = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },

    cleanupListeners() {
      const audio = this.$refs.audioplayer;
      if (audio) {
        // audio.removeEventListener('timeupdate', this.playbackListener);
        audio.removeEventListener('ended', this.endListener);
        audio.removeEventListener('pause', this.pauseListener);
      }
    },

    rewindAudio() {
      const audio = this.$refs.audioplayer;
      audio.currentTime -= 15;
    },

    forwardAudio() {
      const audio = this.$refs.audioplayer;
      audio.currentTime += 15;
    },
  },

  watch: {
    showPlayer(val) {
      this.$emit('addNoScroll', val);
    },
  },
};
