import VueSlider from 'vue-3-slider-component';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.vue';
import mainStore from '../../stores/mainStore';

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },

  components: {
    ToggleSwitch,
    VueSlider,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  computed: {
    dotColor: {
      cache: false,
      get() {
        if (this.store.darkMode) {
          return '#FFFFFF';
        }
        return '#0D0D0D';
      },
    },
  },

  methods: {
    onSliderChange(value) {
      this.store.setLetterSize(value);
      document.body.style.setProperty('--letterSize', this.store.letterSize);
    },
  },

  watch: {},
};
