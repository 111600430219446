export default {
  // ONBOARDING
  'onboarding.skip': 'Overslaan',
  'onboarding.start': 'Start',
  'onboarding.next': 'Volgende',
  // 'onboarding.step1.title': 'Welkom in M',
  // 'onboarding.step1.text': 'Met deze app ontdek je het museum op je eigen manier.',
  'onboarding.step1.link': 'Toegankelijkheidsinstellingen',
  // 'onboarding.step2.title': 'Scan of zoek',
  // 'onboarding.step2.text': 'Aan het begin van elke tentoonstelling kom je een QR-code tegen, die je naar de tentoonstellingspagina op de app leidt',
  // 'onboarding.step3.title': 'Kijk, luister en ontdek',
  // 'onboarding.step3.text': "De verschillende tours in deze app brengen de verhalen van de kunstwerken tot leven aan de hand van audio, video, tekst en foto's.",

  // HOME
  // 'home.title': 'Uitgelicht',
  // 'home.text': 'Welkom in M! Hier vind je alvast enkele suggesties om je bezoek te starten.',
  // 'home.today.title': 'Vandaag in M',
  // 'home.today.text': 'Ontdek de nieuwe collectiepresentatie of ga eens kijken in een tijdelijke tentoonstelling',
  // 'home.extra.title': 'Ook in M',
  // 'home.extra.text': 'Het zomert in M. Ontdek hier wat er allemaal te beleven valt.',

  // SETTINGS
  // 'settings.title': 'Instellingen',
  // 'settings.text': '',
  'settings.language': 'Taal',
  'settings.accessibility': 'Toegankelijkheid',

  // LANGUAGES
  'languages.title': 'Taal wijzigen',
  'languages.select-title': 'Selecteer je taal',

  //   Offline
  'offline.title': 'Je internetverbinding lijkt instabiel.',
  'offline.text1': 'Gelukkig hebben we gratis Wi-Fi in het museum!',
  'offline.text2': 'Ga naar de instellingen van je GSM en selecteer "M-Publiek" in het Wi-Fi menu.',

  // ACCESSIBILITY
  // 'accessibility.title': 'Toegankelijkheid',
  // 'accessibility.text': '',
  'accessibility.option1': 'Voorleesfunctie',
  'accessibility.option2': 'Tekstgrootte',
  'accessibility.option3': 'Donker thema',
  'accessibility.option4': 'Zoomen toestaan',

  // GENERAL
  'app.id.prefix': 'Nummer',
  'button.back': 'Terug',
  'actionbar.home': 'Home',
  'actionbar.scan': 'Scan of zoek',
  'actionbar.info': 'Info',

  // INFO
  // 'info.title': 'Info',
  // 'info.text': 'Waar ben je naar op zoek?',
  'info.floorplan': 'Grondplan bekijken',
  'info.onboarding': 'Hoe de app gebruiken?',
  'info.newsletter': 'Blijf op de hoogte',
  'info.newsletter.required': 'Vereist',
  'info.newsletter.email': 'E-mailadres',
  'info.newsletter.firstName': 'Voornaam',
  'info.newsletter.surname': 'Achternaam',
  'info.newsletter.language.label': 'Taal',
  'info.newsletter.language': 'Nederlands',
  'info.newsletter.emails.label': 'Ik ontvang graag',
  'info.newsletter.emails.monthly': 'de maandelijkse nieuwsbrief waarbij we met je delen wat er in M te beleven valt',
  'info.newsletter.emails.fund': 'informatie over het museumfonds van M, M-LIFE',
  'info.newsletter.emails.midzomer': 'informatie over het cultuur- en muziekfestival, M-IDZOMER',
  'info.newsletter.emails.zomervanm': 'alles over de activiteiten en het programma van de Zomer van M',
  'info.newsletter.emails.b2b': 'het drie maandelijkse B2B-nieuws over het corporate hospitality-aanbod, nocturnes, teambuildings, ...',
  'info.newsletter.emails.family': 'alles over de familie-activiteiten en het programma van de Zomer van M',
  'info.newsletter.emails.magazine': 'het digitale Magazine van M (twee keer per jaar)',
  'info.newsletter.emails.free': 'informatie over het gratis aanbod aan lezingen, lessen, debatten en ontmoetingen; Denkvoer',
  'info.newsletter.emails.playground': 'informatie over Playground, het live arts festival van M en STUK',
  'info.newsletter.educational': 'Onderwijsnieuwsbrieven',
  'info.newsletter.educational.preschool': 'kleuteronderwijs',
  'info.newsletter.educational.primary': 'lager onderwijs',
  'info.newsletter.educational.secondary': 'secundair onderwijs',
  'info.newsletter.educational.university': 'hoger onderwijs',
  'info.newsletter.educational.adult': 'volwassenenonderwijs',
  'info.newsletter.educational.artschool': 'deeltijds kunstonderwijs',
  'info.newsletter.privacy.title': 'Privacy + voorwaarden',
  'info.newsletter.privacy.input': 'Ik heb de <a href="https://www.mleuven.be/nl/privacybeleid" target="_blank">privacyverklaring</a> van M gelezen en goedgekeurd.',
  'info.newsletter.submit': 'Ik schrijf mij in',
  'info.about': 'Over M Leuven',

  // INFO DETAIL
  // 'floorplan.title': 'Grondplan',
  // 'floorplan.text': 'Even de weg kwijt?',
  // 'about.title': 'Over M Leuven',
  // 'about.text': '<p>M Leuven toont oude en nieuwe kunst geïnspireerd door de veelzijdigheid van Leuven. De collectie concentreert zich vooral rond de kunstproductie in Leuven en Brabant van de middeleeuwen tot de 19e eeuw, met ook een rijke collectie aan hedendaagse kunst.</p><p>Naast de vaste collectie presenteert M tijdelijke tentoonstellingen van zowel oude meesters als hedendaagse kunstenaars.</p>',
  // 'about.button': 'Ga naar de website',
  // 'newsletter.title': 'Nieuwsbrief',
  // 'newsletter.text': 'Schrijf je hieronder in op een van de nieuwsbrieven en dan zorgen wij op onze beurt voor jouw maandelijkse portie nieuws van M.',
  // 'visit.title': 'Na je bezoek',
  // 'visit.subtitle1': 'Ontdek Barbóék in M',
  // 'visit.paragraph1': 'Je kunt er museumsouvenirs kopen, maar ook kunstboeken en publicaties die verband houden met M en de tentoonstellingen in M. En natuurlijk is het dé plek om bij een kop koffie en een stukje taart na te praten over je bezoek aan M.',
  // 'visit.subtitle2': 'Ontdek Barbóék in M',
  // 'visit.paragraph2': 'Je kunt er museumsouvenirs kopen, maar ook kunstboeken en publicaties die verband houden met M en de tentoonstellingen in M. En natuurlijk is het dé plek om bij een kop koffie en een stukje taart na te praten over je bezoek aan M.',

  // SCAN
  'scan.title': 'Scan of zoek',
  'scan.label': 'Of geef nummer in van kunstwerk',
  'scan.placeholder': 'Nummer kunstwerk',
  'scan.loading': 'Zoeken...',
  'scan.error': 'Geen kunstwerk gevonden',
  'scan.loadingCamera': 'Laden...',
  'scan.errorOther': 'Er is iets misgegaan, probeer opnieuw',

  // EXPO
  'expo.label': 'Tentoonstelling',
  'expo.tours': 'Tours',
  'expo.artworks': 'Kunstwerken in deze tentoonstelling',
  'expo.activities': 'Activiteiten in M',

  // ARTWORK
  'artwork.info': 'Meer info over kunstwerk',
  'artwork.video': 'Video bekijken',
  'artwork.specifications': 'Specificaties bekijken',

  // AUDIOPLAYER
  'audioplayer.transcriptShow': 'Tekst tonen',
  'audioplayer.transcriptHide': 'Tekst sluiten',

  // TOUR
  'tour.start': 'Start tour',
  'tour.content': 'In deze tour',
  'tour.stop': 'Tour stoppen',
  // 'tour.end': 'Beëindig de tour',
  'tour.to': 'Ga naar',
  // 'tour.ending': 'Dit is het einde van de tour',
  'tour.expected': 'Verwachte activiteiten',
  'tour.newsletter': 'Blijf op de hoogte',
  'tour.afterwards': 'Na je bezoek',
  'tour.home': 'Terug naar home',
  'tour.overlay.ending': 'Tour stoppen',
  'tour.overlay.ending.title': 'Ben je zeker dat je deze tour wil stoppen?',
  'tour.overlay.ending.paragraph': ' ',
  'tour.overlay.ending.yes': 'Ja, ik wil de tour stoppen',
  'tour.overlay.ending.no': 'Nee, ik ben nog niet klaar',
  'tour.overlay.floorplan': 'Grondplan',
  'tour.overlay.overview': 'Overzicht tour',
  'tour.overlay.scan': 'Scan of zoek',
  'tour.overlay.settings': 'Instellingen',

  // install
  'download.prompt': 'Download de app',
  'install.title': 'Installeer de app!',
  'install.text': 'Je kunt deze applicatie nu installeren op je apparaat voor gemakkelijkere toegang en een betere ervaring. Klik op de knop hieronder om de installatie te starten.',
  'install.cta': 'Installeren',

  // specs
  'specs.header': 'Technische gegevens',
  'specs.identification': 'Identificatie',
  'specs.title': 'Titel',
  'specs.objectNames': 'Objectnaam',
  'specs.location': 'Standplaats',
  'specs.creation': 'Vervaardiging',
  'specs.creators': 'Vervaardiger(s)',
  'specs.period': 'Periode',
  'specs.date': 'Datering',
  'specs.date.from': 'van',
  'specs.date.to': 'tot',
  'specs.technical': 'Materiaal & Technieken',
  'specs.materials': 'Materiaal',
  'specs.techs': 'Techniek',
  'specs.dimensions': 'Afmetingen',

  // errors
  'error.404': 'Deze pagina bestaat helaas niet (meer)',
  'error.500': 'Er liep helaas iets fout met het ophalen van de juiste gegevens.',

  'error.general': 'Er liep iets mis',
  'error.notfound': 'Pagina niet gevonden',
  'error.home': 'Terug naar de homepagina',
};
