import mainStore from '../../stores/mainStore';

export default {
  props: {},

  components: {},

  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.store.setDefferedPrompt(e);
      // Update UI notify the user they can install the PWA
      if (this.store.promptInstall) {
        this.store.showInstallPrompt();
      } else if (!this.store.installCancelled) {
        this.store.showInstallPrompt();
      }
    });
  },

  data() {
    const store = mainStore();

    return {
      store,
    };
  },

  computed: {},

  methods: {
    onCloseClick() {
      this.store.cancelInstall();
    },

    onInstallClick() {
      // Hide the app provided install promotion
      this.store.cancelInstall();
      // Show the install prompt
      if (this.store.deferredPrompt) {
        this.store.deferredPrompt.prompt();
        this.store.setDefferedPrompt(null);
      }
    },
  },

  watch: {},
};
