import mainStore from '../../../stores/mainStore';
import BackButton from '../../BackButton/BackButton.vue';

export default {
  props: {},

  components: {
    BackButton,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
      languageOptions: [
        {
          val: 'nl',
          label: 'Nederlands',
        },
        {
          val: 'en',
          label: 'English',
        },
        {
          val: 'fr',
          label: 'Français',
        },
      ],
    };
  },

  computed: {},

  methods: {
    onLangClick(lang) {
      // set language
      this.store.setLanguage(lang);
      // navigate
      if (this.store.entryRoute) {
        this.$router.push(this.store.entryRoute);
      } else {
        this.$router.push('/');
      }
    },
  },

  watch: {},
};
