<template>
  <div class="page-wrapper" :class="{ noscroll: overlayVisible }">
    <div class="expo" v-if="expo">
      <div class="expo__back">
        <BackButton :isWhite="true" />
      </div>

      <Cover :info="expo.headerImage.caption" :image="expo.headerImage" :isBackground="true" />

      <div class="expo__details">
        <div class="expo__border-bottom">
          <h1 class="title">{{ expo.title }}</h1>
          <p class="expo__subtitle" v-if="expo.subtitle">{{ expo.subtitle }}</p>

          <div v-if="expo.intro" class="expo__description" v-html="expo.intro" />
        </div>
      </div>

      <div v-if="expo.tours.length" class="expo__tours">
        <div class="expo__border-bottom">
          <h2 class="subtitle">{{ $t('expo.tours') }}</h2>
          <div class="expo__tours__list">
            <RouterLink :to="{ name: 'tours', params: { id: tour.uuid } }" class="expo__tours__list-item" v-for="tour in expo.tours">
              <div>
                <h3 class="expo__subtitle">{{ tour.title }}</h3>
                <div v-if="tour.intro" class="expo__tours__description" v-html="tour.intro" />
                <div class="expo__tours__wrapper">
                  <p>{{ tour.duration }}</p>
                  <p>{{ tour.stopCount }} stops</p>
                </div>
              </div>
              <div v-if="tour.tag" class="expo__tours__category">
                <p>{{ tour.tag }}</p>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>

      <div v-if="expo.artworks.length" class="expo__artworks">
        <div class="expo__border-bottom">
          <h2 class="subtitle">{{ $t('expo.artworks') }}</h2>
          <div class="expo__artworks__list">
            <div v-for="artwork in expo.artworks" class="expo__artworks__list-item-wrapper">
              <RouterLink :to="{ name: 'artwork', params: { id: artwork.uuid } }" class="expo__artworks__list-item">
                <div class="expo__artworks__image">
                  <img :src="artwork.image" alt="" />
                </div>
                <div class="expo__artworks__wrapper">
                  <div>
                    <p class="expo__artworks__title">{{ artwork.title }}</p>
                    <p class="expo__artworks__artist">
                      <span v-for="(creator, index) in artwork.creators" :key="artwork.uuid">
                        {{ creator.name }}
                        <span v-if="index < artwork.creators.length - 1">, </span>
                      </span>
                    </p>
                  </div>
                  <div class="expo__artworks__details">
                    <div class="expo__artworks__details__wrapper">
                      <p v-if="artwork.appId">{{ $t('app.id.prefix') }} {{ artwork.appId }}</p>
                      <p>{{ artwork.location }}</p>
                    </div>
                    <div class="expo__artworks__icons">
                      <div v-if="artwork.audio">
                        <img src="../../../assets/icons/audio-white.svg" alt="" v-if="store.darkMode" />
                        <img src="../../../assets/icons/audio.svg" alt="" v-else />
                      </div>
                      <div v-if="artwork.video">
                        <img src="../../../assets/icons/video-white.svg" alt="" v-if="store.darkMode" />
                        <img src="../../../assets/icons/video.svg" alt="" v-else />
                      </div>
                    </div>
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div v-if="expo.activities.length" class="expo__activities">
        <h2 class="subtitle">{{ $t('expo.activities') }}</h2>

        <div class="expo__activities__list">
          <a :href="`https://www.mleuven.be${activity.url}`" target="_blank" class="expo__activities__list-item" v-for="activity in expo.activities">
            <div class="expo__activities__dates">
              <p v-for="(date, index) in activity.dates" class="expo__activities__dates__date">
                <span>{{ format(fromUnixTime(date.start), date.end ? (isSameYear(fromUnixTime(date.start), fromUnixTime(date.end)) ? (!isSameDay(fromUnixTime(date.start), fromUnixTime(date.end)) ? 'dd.MM' : 'dd.MM.yy') : 'dd.MM.yy') : 'dd.MM.yy') }}</span>
                <img v-if="date.end && !isSameDay(fromUnixTime(date.start), fromUnixTime(date.end))" src="../../../assets/icons/arrow-right.svg" alt="" />
                <span v-if="date.end && !isSameDay(fromUnixTime(date.start), fromUnixTime(date.end))">{{ format(fromUnixTime(date.end), 'dd.MM.yy') }}</span>
                <span v-if="index < activity.dates.length - 1">, </span>
              </p>
            </div>
            <p class="expo__activities__title">{{ activity.title }}</p>
          </a>
        </div>
      </div>
    </div>
    <ContentLoader v-else-if="!error" />
    <ErrorScreen v-else :error="error" />
    <Actionbar @addNoScroll="(val) => (overlayVisible = val)" />
  </div>
</template>

<script src="./Expo.js"></script>
