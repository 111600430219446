import mainStore from '../../../stores/mainStore';
import OnboardingStep from '../../OnboardingStep/OnboardingStep.vue';
import Pagination from '../../Pagination/Pagination.vue';

export default {
  props: {},

  components: {
    OnboardingStep,
    Pagination,
  },

  mounted() {
    if (this.$refs.onboardingInnerRef) {
      this.shadowHandler();
      window.addEventListener('scroll', this.scrollHandler);
      window.addEventListener('resize', this.shadowHandler);
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      windowHeight: `${window.innerHeight}px`,
      currentStep: 0,
      isPrevious: false,
      addShadow: this.addShadow,
      atBottom: false,
    };
  },

  computed: {
    currentStepData() {
      // geeft huidige stap weer
      return this.steps[this.currentStep];
    },
    isLastStep() {
      // controleert of huidige stap laatste stap is
      return this.steps ? this.currentStep === this.steps.length - 1 : '';
    },
    steps: {
      get() {
        return this.store.settings.onboarding;
      },
    },
  },

  methods: {
    setCurrentStep(index) {
      // check of nieuwe stap stap terug is om transitie klasse aan te passen
      if (index < this.currentStep) {
        this.isPrevious = true;
      } else {
        this.isPrevious = false;
      }
      // zet huidige stap in op nieuwe index
      this.currentStep = index;
    },

    shadowHandler() {
      if (this.$refs.onboardingInnerRef) {
        this.addShadow = this.$refs.onboardingInnerRef.clientHeight > window.innerHeight;
      }
    },

    scrollHandler() {
      if (this.$refs.onboardingInnerRef) {
        this.$refs.onboardingInnerRef.classList.toggle('at-bottom', window.innerHeight + window.scrollY >= document.body.offsetHeight);
      }
    },

    onStepChange() {
      this.shadowHandler();
    },

    nextStep() {
      this.isPrevious = false;
      if (this.currentStep < this.steps.length - 1) {
        // controleert of huidige stap niet de laatste stap is
        this.currentStep += 1;
      } else {
        this.endOnboarding();
      }
    },
    previousStep() {
      this.isPrevious = true;
      // controleert of huidige stap niet de eerste stap is
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    onSkipClick() {
      this.endOnboarding();
    },
    dragHandler({ movement: [x], dragging }) {
      // controleer of de gebruiker aan het slepen is
      if (dragging) {
        // controleer of de gebruiker naar rechts swiped en niet bij de eerste stap is
        if (x > 100 && this.currentStep > 0) {
          this.previousStep();
        }
        // controleer of de gebruiker naar links swiped en niet bij de laatste stap is
        else if (x < -100 && this.currentStep < this.steps.length - 1) {
          this.nextStep();
        }
      }
    },
    endOnboarding() {
      // set onboarded on true
      this.store.setOnboarded(true);
      // navigate
      if (this.store.entryRoute) {
        this.$router.push(this.store.entryRoute);
      } else {
        this.$router.push('/');
      }
    },
  },

  watch: {},

  unmounted() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.shadowHandler);
  },
};
