import _keys from 'lodash/keys';
import mainStore from '../../../stores/mainStore';
import StopAccordion from '../../StopAccordion/StopAccordion.vue';
import Audioplayer from '../../Audioplayer/Audioplayer.vue';
import BackButton from '../../BackButton/BackButton.vue';
import Cover from '../../Cover/Cover.vue';
import ExitTourOverlay from '../../ExitTourOverlay/ExitTourOverlay.vue';
import Floorplan from '../../Floorplan/Floorplan.vue';
import ContentLoader from '../../ContentLoader/ContentLoader.vue';
import OverviewTourOverlay from '../../OverviewTourOverlay/OverviewTourOverlay.vue';
import Scan from '../../Scan/Scan.vue';
import Settings from '../Settings/Settings.vue';
import Accordion from '../../Accordion/Accordion.vue';

export default {
  props: {
    tourId: {
      type: String,
    },
    index: {
      type: String,
    },
  },

  components: {
    StopAccordion,
    Accordion,
    Audioplayer,
    BackButton,
    Cover,
    ExitTourOverlay,
    Floorplan,
    OverviewTourOverlay,
    Scan,
    Settings,
    ContentLoader,
  },

  mounted() {
    if (!this.tour) {
      this.store.getTour(this.tourId, Number(this.index)).then(() => {
        this.loading = false;
      });
    } else if (!this.stop) {
      this.store.getStops(this.tourId, Number(this.index)).then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      activeId: 0,
      showImgInfo: false,
      showPlayer: false,
      showExitTour: false,
      showFloorplan: false,
      showTourOverview: false,
      showScan: false,
      showSettings: false,
      tooltipVisible: false,
      currentStep: 1,
      accordionAudioData: null,
      audioKey: 0,
    };
  },

  computed: {
    stop: {
      get() {
        return this.store.tours[this.tourId] ? this.store.tours[this.tourId].stops[this.index] : null;
      },
    },
    tour: {
      get() {
        return this.store.tours[this.tourId] ? this.store.tours[this.tourId].tour : null;
      },
    },
    tourModel: {
      get() {
        return this.store.tours[this.tourId];
      },
    },
    stepsNo: {
      cache: false,
      get() {
        return this.tour.stopCount;
      },
    },
    floorplanHasData: {
      cache: false,
      get() {
        return this.store.settings.floorplan?.length > 0;
      },
    },
    artworkAccordionData: {
      cache: false,
      get() {
        const accordions = [];
        this.stop.accordions.forEach((acc) => {
          const accordion = {
            top: acc.title,
            bottom: acc.subParagraphs,
            uuid: acc.uuid,
          };
          accordions.push(accordion);
        });
        accordions.push({
          top: this.$t('artwork.specifications'),
          bottom: [
            {
              type: 'specs',
              artwork: this.stop.artwork,
            },
          ],
        });
        return accordions;
      },
    },
    accordionData: {
      cache: false,
      get() {
        const accordions = [];
        this.stop.accordions.forEach((acc) => {
          const accordion = {
            top: acc.title,
            bottom: acc.subParagraphs,
            uuid: acc.uuid,
          };
          accordions.push(accordion);
        });
        return accordions;
      },
    },
    hasOverlay: {
      cache: false,
      get() {
        if (this.showExitTour || this.showFloorplan || this.showTourOverview || this.showScan || this.showSettings || this.tooltipVisible) {
          return true;
        }
        return false;
      },
    },
    nextIndex: {
      get() {
        return Number(this.index) + 1;
      },
    },
    prevIndex: {
      get() {
        return Number(this.index) - 1;
      },
    },
    isArtworkStop: {
      get() {
        return this.stop.artwork;
      },
    },
    artworkCreators: {
      get() {
        const names = this.stop.artwork.creators.map((c) => c.name);
        return names.join(', ');
      },
    },
    audioData: {
      cache: false,
      get() {
        if (this.accordionAudioData) {
          return this.accordionAudioData;
        }
        if (this.stop.artwork) {
          if (this.stop.audio) {
            return this.stop;
          }
          if (this.stop.artwork.audio) {
            return this.stop.artwork;
          }
        }
        return this.stop;
      },
    },
  },

  emits: ['closeTour'],

  methods: {
    closeTour() {
      this.$router.replace({ name: 'tours', params: { id: this.tourId } });
    },

    closeModal(val) {
      switch (val) {
        case 'exitTour':
          this.showExitTour = false;
          break;
        case 'floorplan':
          this.showFloorplan = false;
          break;
        case 'tourOverview':
          this.showTourOverview = false;
          break;
        case 'scan':
          this.showScan = false;
          break;
        case 'settings':
          this.showSettings = false;
          break;
        case 'all':
          this.showExitTour = false;
          this.showFloorplan = false;
          this.showTourOverview = false;
          this.showScan = false;
          this.showSettings = false;
          break;
        default:
          break;
      }
    },

    openPlayer(media = null) {
      this.showPlayer = false;
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.cleanupListeners();
      }

      this.accordionAudioData = null;

      if (media && media.type && media.type === 'audio' && this.stop.artwork) {
        this.accordionAudioData = {
          image: {
            urlNormal: this.stop.artwork.image.urlNormal,
          },
          audio: media,
        };
      } else if (media && media.type && media.type === 'audio') {
        this.accordionAudioData = {
          image: {
            urlNormal: this.stop.image.urlNormal,
          },
          audio: media,
        };
      }

      this.showPlayer = true;

      this.store.playAudio(this.audioData);

      this.$nextTick(() => {
        this.store.setAudioDuration();
      });
    },

    closePlayer() {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.cleanupListeners();
      }
      this.accordionAudioData = null;
      this.showPlayer = false;
      this.store.stopAudio();
    },

    togglePlayer() {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.cleanupListeners();
      }
      this.showPlayer = false;
      this.accordionAudioData = null;
      this.store.stopAudio();
    },

    openAccordionAudio(media) {
      this.accordionAudioData = {
        image: {
          urlNormal: this.stop.artwork.image.urlNormal,
        },
        audio: media,
      };
      this.showPlayer = true;
    },
  },

  watch: {
    index(val) {
      if (!this.stop && !this.loading) {
        this.store.getStops(this.tourId, val);
      }

      if (val % this.tourModel.limit === 2) {
        // here we need to prefect if neccessery
        const iNext = Number(val) + Number(this.tourModel.limit);
        const iPrev = Number(val) - Number(this.tourModel.limit);

        if (iNext < this.tour.stopCount) {
          if (_keys(this.tourModel.stops).indexOf(`${iNext}`) < 0) {
            this.store.getStops(this.tourId, iNext);
          }
        }

        if (iPrev > 0) {
          if (_keys(this.tourModel.stops).indexOf(`${iPrev}`) < 0) {
            this.store.getStops(this.tourId, iPrev);
          }
        }
      }
    },
  },
};
