import Accordion from '../Accordion/Accordion.vue';
import BackButton from '../BackButton/BackButton.vue';
import mainStore from '../../stores/mainStore';

export default {
  props: {},

  components: {
    Accordion,
    BackButton,
  },

  mounted() {},

  data() {
    const store = mainStore();
    return {
      store,
    };
  },

  computed: {
    newsletter: {
      get() {
        return this.store.settings.newsletter;
      },
    },
    accordionData: {
      get() {
        return [
          {
            top: this.$t('info.newsletter.emails.label'),
            bottom: `<div class="mc-field-group input-group form-item form-item--checkbox form-item--type"><ul><li><input type="checkbox" value="1" name="group[11797][1]" id="mce-group[11797]-11797-0" /><label for="mce-group[11797]-11797-0">${this.$t('info.newsletter.emails.monthly')}</label></li><li><input type="checkbox" value="2" name="group[11797][2]" id="mce-group[11797]-11797-1" /><label for="mce-group[11797]-11797-1">${this.$t('info.newsletter.emails.fund')}</label></li><li><input type="checkbox" value="8192" name="group[11797][8192]" id="mce-group[11797]-11797-2" /><label for="mce-group[11797]-11797-2">${this.$t('info.newsletter.emails.midzomer')}</label></li><li><input type="checkbox" value="16384" name="group[11797][16384]" id="mce-group[11797]-11797-3" /><label for="mce-group[11797]-11797-3">${this.$t('info.newsletter.emails.zomervanm')}</label></li><li><input type="checkbox" value="32768" name="group[11797][32768]" id="mce-group[11797]-11797-4" /><label for="mce-group[11797]-11797-4">${this.$t('info.newsletter.emails.b2b')}</label></li><li><input type="checkbox" value="1099511627776" name="group[11797][1099511627776]" id="mce-group[11797]-11797-5" /><label for="mce-group[11797]-11797-5">${this.$t('info.newsletter.emails.family')}</label></li><li><input type="checkbox" value="35184372088832" name="group[11797][35184372088832]" id="mce-group[11797]-11797-6" /><label for="mce-group[11797]-11797-6">${this.$t('info.newsletter.emails.magazine')}</label></li><li><input type="checkbox" value="512" name="group[11797][512]" id="mce-group[11797]-11797-7" /><label for="mce-group[11797]-11797-7">${this.$t('info.newsletter.emails.free')}</label></li><li><input type="checkbox" name="group[11797][67108864]" id="mce-group[11797]-11797-8" value="" /><label for="mce-group[11797]-11797-8">${this.$t('info.newsletter.emails.playground')}</label></li></ul></div>`,
          },
          {
            top: this.$t('info.newsletter.educational'),
            bottom: `<div class="mc-field-group input-group form-item form-item--checkbox form-item--type"><ul><li><input type="checkbox" value="4" name="group[11801][4]" id="mce-group[11801]-11801-0" /><label for="mce-group[11801]-11801-0">${this.$t('info.newsletter.educational.preschool')}</label></li><li><input type="checkbox" value="8" name="group[11801][8]" id="mce-group[11801]-11801-1" /><label for="mce-group[11801]-11801-1">${this.$t('info.newsletter.educational.primary')}</label></li><li><input type="checkbox" value="16" name="group[11801][16]" id="mce-group[11801]-11801-2" /><label for="mce-group[11801]-11801-2">${this.$t('info.newsletter.educational.secondary')}</label></li><li><input type="checkbox" value="32" name="group[11801][32]" id="mce-group[11801]-11801-3" /><label for="mce-group[11801]-11801-3">${this.$t('info.newsletter.educational.university')}</label></li><li><input type="checkbox" value="64" name="group[11801][64]" id="mce-group[11801]-11801-4" /><label for="mce-group[11801]-11801-4">${this.$t('info.newsletter.educational.adult')}</label></li><li><input type="checkbox" value="128" name="group[11801][128]" id="mce-group[11801]-11801-5" /><label for="mce-group[11801]-11801-5">${this.$t('info.newsletter.educational.artschool')}</label></li></ul></div>`,
          },
        ];
      },
    },
  },

  emits: ['closeOverlay'],

  methods: {
    close() {
      this.$emit('closeOverlay', 'newsletter');
    },
  },

  watch: {},
};
