<template>
  <div class="tour-end" v-if="tour">
    <div class="tour-end__back">
      <BackButton />
    </div>

    <div v-if="!showNewsletter && !showPostVisit" class="tour-end__content">
      <p class="tour-end__subtitle">{{ tour.title }}</p>
      <div class="tour-end__details">
        <Paragraphs @playAudio="togglePlayer" :paragraphs="tour.tourEnd" />
      </div>

      <div class="tour-end__accordion">
        <Accordion v-if="tour.activities.length" :data="accordionData">
          <template #content-0>
            <div class="tour-end__activities__list">
              <a :href="`https://www.mleuven.be${activity.url}`" target="_blank" class="tour-end__activities__list-item" v-for="activity in tour.activities" :key="activity.uuid">
                <div class="tour-end__activities__dates">
                  <p v-for="(date, index) in activity.dates" class="tour-end__activities__dates__date" :key="`${date.start}-${date.end}`">
                    <span>{{ format(fromUnixTime(date.start), date.end ? (isSameYear(fromUnixTime(date.start), fromUnixTime(date.end)) ? (!isSameDay(fromUnixTime(date.start), fromUnixTime(date.end)) ? 'dd.MM' : 'dd.MM.yy') : 'dd.MM.yy') : 'dd.MM.yy') }}</span>
                    <img v-if="date.end && !isSameDay(fromUnixTime(date.start), fromUnixTime(date.end))" src="../../../assets/icons/arrow-right.svg" alt="" />
                    <span v-if="date.end && !isSameDay(fromUnixTime(date.start), fromUnixTime(date.end))">{{ format(fromUnixTime(date.end), 'dd.MM.yy') }}</span>
                    <span v-if="index < activity.dates.length - 1">, </span>
                  </p>
                </div>
                <p class="tour-end__activities__title">{{ activity.title }}</p>
              </a>
            </div>
          </template>
        </Accordion>
        <div class="tour-end__list">
          <div class="tour-end__list__item" @click="showNewsletter = true">
            <p>{{ $t('tour.newsletter') }}</p>
            <img src="../../../assets/icons/arrow-right.svg" alt="Right arrow" />
          </div>
          <div class="tour-end__list__item" @click="showPostVisit = true" v-if="hasPostVisitContent">
            <p>{{ $t('tour.afterwards') }}</p>
            <img src="../../../assets/icons/arrow-right.svg" alt="Right arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="tour-end__footer">
      <Transition name="audioplayer">
        <Audioplayer class="tour__audioplayer" ref="audioPlayer" :data="artwork" v-show="showPlayer" @closeAudioPlayer="togglePlayer" @addNoScroll="(val) => (overlayVisible = val)" />
      </Transition>
      <div class="tour-end__navigation">
        <RouterLink replace :to="{ name: 'tour-stop', params: { tourId: id, index: tour.stopCount } }" class="button__icon button__white button__arrow-right">
          <img src="../../../assets/icons/arrow-left-white.svg" alt="Left arrow" v-if="store.darkMode" />
          <img src="../../../assets/icons/arrow-left.svg" alt="Left arrow" v-else />
        </RouterLink>
        <RouterLink replace :to="{ name: 'home' }" class="button__icon button__white button__arrow-right">
          <span>{{ $t('tour.home') }}</span>
          <img src="../../../assets/icons/arrow-right-white.svg" alt="Right arrow" v-if="store.darkMode" />
          <img src="../../../assets/icons/arrow-right.svg" alt="Right arrow" v-else />
        </RouterLink>
      </div>
    </div>
  </div>
  <Newsletter v-if="showNewsletter" @closeOverlay="closeOverlay" class="tour-end__newsletter" />
  <PostVisit @playAudio="togglePlayer" v-if="showPostVisit" @closeOverlay="closeOverlay" />
</template>

<script src="./TourEnd.js"></script>
