<template>
  <div class="accordion">
    <div class="accordion__item" v-for="(item, id) in data" :key="id" :class="showId === id ? 'accordion__item__open' : ''">
      <div v-if="item.top && item.bottom" class="accordion__item__top" @click="openAccordion(id)">
        <p>{{ item.top }}</p>
        <img src="../../assets/icons/plus-white.svg" alt="" class="accordion__item__top__img" v-if="store.darkMode" />
        <img src="../../assets/icons/plus.svg" alt="" class="accordion__item__top__img" v-else />
      </div>
      <div class="accordion__item__bottom">
        <slot :name="`content-${item.name}`">
          <div v-if="item.bottom.length">
            <div v-for="bottom in item.bottom" :key="bottom.uuid" class="accordion__item__paragraph">
              <div v-if="bottom.type && bottom.type === 'text_app'" v-html="bottom.text"></div>
              <div v-if="bottom.type && bottom.type === 'media_app'">
                <Cover :autoHeight="true" v-if="(bottom.media && bottom.media.type && bottom.media.type === 'resourcespace') || bottom.media.type === 'image'" :image="bottom.media" :info="bottom.media.caption" />
                <div v-if="bottom.media && bottom.media.type && bottom.media.type === 'video_embed'" class="accordion__video">
                  <iframe ref="videoEmbedRef" width="100%" height="100%" :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoID(bottom.media.url)}?enablejsapi=1`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div v-if="bottom.media && bottom.media.type && bottom.media.type === 'video'" class="accordion__video">
                  <video controls width="100%">
                    <source :src="bottom.media.url" type="video/mp4" />
                  </video>
                </div>
                <div v-if="bottom.media && bottom.media.type && bottom.media.type === 'audio'" class="accordion__player__button" @click="openPlayer(bottom.media)">
                  <img src="../../assets/icons/play-btn.svg" alt="play transcript" />
                  <p>{{ bottom.media.title }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="item.bottom[0] && item.bottom[0].media && item.bottom[0].media.type && item.bottom[0].media.type === 'video_embed'" class="accordion__video">
              <iframe ref="videoEmbedRef" width="100%" height="100%" :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoID(item.bottom[0].media.url)}?enablejsapi=1`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div v-else v-html="item.bottom"></div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script src="./Accordion.js"></script>
