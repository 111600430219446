import mainStore from '../../stores/mainStore';

export default {
  props: {},

  components: {},

  mounted() {},

  data() {
    const store = mainStore();

    return {
      store,
    };
  },

  computed: {},

  emits: ['closeTour', 'closeModal'],

  methods: {
    closeTour() {
      this.$emit('closeTour');
    },
    closeOverlay(val) {
      this.$emit('closeModal', val);
    },
  },

  watch: {},
};
