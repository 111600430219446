import mainStore from '../../../stores/mainStore';
import Actionbar from '../../Actionbar/Actionbar.vue';

export default {
  props: {},

  components: {
    Actionbar,
  },

  mounted() {},

  data() {
    const store = mainStore();

    return {
      store,
      overlayVisible: false,
    };
  },

  computed: {
    featured: {
      get() {
        return this.store.settings.home.featured;
      },
    },
    today: {
      get() {
        return this.store.settings.home.today;
      },
    },
    activities: {
      get() {
        return this.store.settings.home.activities;
      },
    },
  },

  methods: {
    openSettings() {
      this.$router.push('/settings');
    },
    playAudio(src) {
      this.store.playAudio(src);
    },
  },

  watch: {},
};
