export default {
  props: {},

  components: {},

  mounted() {},

  data() {
    return {
      isOpen: true,
    };
  },

  computed: {},

  methods: {
    onCloseClick() {
      this.isOpen = false;
    },
  },

  watch: {},
};
