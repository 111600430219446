import { RouterLink } from 'vue-router';

export default {
  props: {
    error: {
      type: String,
      required: true,
    },
  },

  components: {
    RouterLink,
  },

  mounted() {},

  data() {
    return {};
  },

  computed: {},

  methods: {},

  watch: {},
};
