// eslint-disable-next-line import/no-extraneous-dependencies
import { fromUnixTime, format, isSameYear, isSameDay } from 'date-fns';
import mainStore from '../../../stores/mainStore';
import Actionbar from '../../Actionbar/Actionbar.vue';
import BackButton from '../../BackButton/BackButton.vue';
import Cover from '../../Cover/Cover.vue';
import ContentLoader from '../../ContentLoader/ContentLoader.vue';
import ErrorScreen from '../../ErrorScreen/ErrorScreen.vue';

export default {
  props: {
    id: {
      type: String,
    },
  },

  components: {
    Actionbar,
    BackButton,
    Cover,
    ContentLoader,
    ErrorScreen,
  },

  mounted() {
    if (!this.expo) {
      this.store.getExpos(this.id)?.then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      overlayVisible: false,
      showImgInfo: false,
      showPlayerId: null,
    };
  },

  computed: {
    expo: {
      get() {
        return this.store.expos[this.id];
      },
    },
    error: {
      get() {
        return this.store.errors.expos[this.id];
      },
    },
  },

  methods: {
    format,
    fromUnixTime,
    isSameYear,
    isSameDay,
    toggleAudioplayer(id) {
      if (this.showPlayerId === id) {
        this.showPlayerId = null;
      } else {
        this.showPlayerId = id;
      }
    },
    togglePlayer() {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.cleanupListeners();
      }
      this.showPlayer = !this.showPlayer;
    },
  },

  watch: {},
};
