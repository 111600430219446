import mainStore from '../../../stores/mainStore';
import Actionbar from '../../Actionbar/Actionbar.vue';
import Audioplayer from '../../Audioplayer/Audioplayer.vue';
import BackButton from '../../BackButton/BackButton.vue';
import Cover from '../../Cover/Cover.vue';
import ContentLoader from '../../ContentLoader/ContentLoader.vue';
import ErrorScreen from '../../ErrorScreen/ErrorScreen.vue';

export default {
  props: {
    id: {
      type: String,
    },
  },

  components: {
    Actionbar,
    Audioplayer,
    BackButton,
    Cover,
    ContentLoader,
    ErrorScreen,
  },

  mounted() {
    if (!this.tour) {
      this.store.getTour(this.id).then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },

  data() {
    const store = mainStore();

    return {
      store,
      tourOpen: false,
      showImgInfo: false,
      overlayVisible: false,
      showPlayerId: null,
      loading: true,
    };
  },

  computed: {
    tour: {
      get() {
        return this.store.tours[this.id] ? this.store.tours[this.id].tour : null;
      },
    },
    error: {
      get() {
        return this.store.errors.tours[this.id];
      },
    },
    historyRoute: {
      get() {
        return this.store.customHistory.tours[this.id] ? this.store.customHistory.tours[this.id] : null;
      },
    },
  },

  methods: {
    toggleAudioplayer(id) {
      if (this.showPlayerId === id) {
        this.showPlayerId = null;
      } else {
        this.showPlayerId = id;
      }
    },
    getCreators(creators) {
      const names = creators.map((c) => c.name);
      return names.join(', ');
    },
  },

  watch: {},
};
