export default {
  // ONBOARDING
  'onboarding.skip': 'Passer',
  'onboarding.start': 'Commencez',
  'onboarding.next': 'Suivant',
  // 'onboarding.step1.title': 'Bienvenue chez M',
  // 'onboarding.step1.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'onboarding.step1.link': 'Paramètres d’accessibilité',
  // 'onboarding.step2.title': 'Scanner ou rechercher',
  // 'onboarding.step2.text': 'Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.',
  // 'onboarding.step3.title': 'Écouter et lire',
  // 'onboarding.step3.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',

  // HOME
  // 'home.title': 'À la une',
  // 'home.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'home.today.title': 'Aujourd’hui chez M',
  // 'home.today.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'home.extra.title': 'Également chez M',
  // 'home.extra.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',

  // SETTINGS
  // 'settings.title': 'Paramètres',
  // 'settings.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'settings.language': 'Langue',
  'settings.accessibility': 'Accessibilité',

  // LANGUAGES
  'languages.title': 'Changer de langue',
  'languages.select-title': 'Sélectionnez votre langue',

  //   Offline
  'offline.title': 'Votre connexion internet semble instable.',
  'offline.text1': 'Heureusement, nous avons du Wi-Fi gratuit dans le musée!',
  'offline.text2': 'Allez dans les paramètres de votre votre téléphone portable et sélectionnez "M-Publiek" dans le menu Wi-Fi.',

  // ACCESSIBILITY
  // 'accessibility.title': 'Accessibilité',
  // 'accessibility.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'accessibility.option1': 'Fonction de lecture',
  'accessibility.option2': 'Taille du texte',
  'accessibility.option3': 'Thème sombre',
  'accessibility.option4': 'Autoriser le zoom',

  // GENERAL
  'app.id.prefix': 'Numéro',
  'button.back': 'Retour',
  'actionbar.home': 'Accueil',
  'actionbar.scan': 'Scannez ou recherchez',
  'actionbar.info': 'Info',

  // INFO
  // 'info.title': 'Info',
  // 'info.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  'info.floorplan': 'Voir le plan',
  'info.onboarding': 'Comment utiliser l’application?',
  'info.newsletter': 'Restez informé',
  'info.newsletter.required': 'obligatoire',
  'info.newsletter.email': 'Adresse e-mail',
  'info.newsletter.firstName': 'Prénom',
  'info.newsletter.surname': 'Nom de famille',
  'info.newsletter.language.label': 'Langue',
  'info.newsletter.language': 'Français',
  'info.newsletter.emails.label': 'Je souhaite recevoir',
  'info.newsletter.emails.monthly': "la lettre d'information mensuelle dans laquelle nous partageons avec vous ce qu'il y a à voir et à vivre à M",
  'info.newsletter.emails.fund': 'des informations sur le fond muséal de M, M-LIFE',
  'info.newsletter.emails.midzomer': 'des informations sur le festival de culture et de musique, M-IDZOMER',
  'info.newsletter.emails.zomervanm': "tout savoir sur les activités et le programme de l'été de M",
  'info.newsletter.emails.b2b': "les informations trimestrielles B2B (entreprise à entreprise) sur l'offre d'acceuil aux entreprises, les nocturnes, les consolidations d'équipe ...",
  'info.newsletter.emails.family': "Tout sur les activités familiales et le programme de l'été de M",
  'info.newsletter.emails.magazine': 'le Magazine numérique de M (2 fois par an)',
  'info.newsletter.emails.free': "Informations sur l'offre gratuite de conférences, de cours, de débats et de rencontres ; Denkvoer",
  'info.newsletter.emails.playground': "Informations sur Playground, le festival d'arts vivants de M et STUK",
  'info.newsletter.educational': "La lettre d'information destinée à l'enseignement avec l'offre de M pou",
  'info.newsletter.educational.preschool': "L'enseignement maternel",
  'info.newsletter.educational.primary': "l'enseignement primaire",
  'info.newsletter.educational.secondary': "l'enseignement secondaire",
  'info.newsletter.educational.university': "l'enseignement supérieur",
  'info.newsletter.educational.adult': "l'enseignement pour adultes",
  'info.newsletter.educational.artschool': "l'enseignement artistique à temps partiel",
  'info.newsletter.privacy.title': 'Déclaration sur le respect des données personnelles + conditions',
  'info.newsletter.privacy.input': 'J\'ai lu  et approuvé <a href="https://www.mleuven.be/nl/privacybeleid" target="_blank">la déclaration sur le respect des données personnelles</a> à M.',
  'info.newsletter.submit': "Je m'inscris",
  'info.about': 'À propos de M Leuven',

  // INFO DETAIL
  // 'floorplan.title': 'Plan',
  // 'floorplan.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet proin ut vitae, felis.',
  // 'about.title': 'À propos de M Leuven',
  // 'about.text': '<p>Le musée présente des œuvres d’art anciennes et modernes inspirées par la diversité de Louvain. La collection se concentre principalement sur la production artistique à Louvain et dans le Brabant, du Moyen Âge au 19ème siècle, avec notamment Constantin Meunier, Jef Lambeaux et Georges Minne.</p><p>En plus de la collection permanente, M présente des expositions temporaires de maîtres anciens et d’artistes contemporains.</p>',
  // 'about.button': 'Aller au site web',
  // 'newsletter.title': 'Restez informé sur ce qui se passe chez M',
  // 'newsletter.text': 'Inscrivez-vous ci-dessous à l’une des newsletters et nous nous occuperons de votre dose mensuelle de nouvelles de M.',
  // 'visit.title': 'Après votre visite',
  // 'visit.subtitle1': 'Découvrez Barbóék chez M',
  // 'visit.paragraph1': 'Vous pouvez acheter des souvenirs du musée, ainsi que des livres d’art et des publications en rapport avec M et les expositions à M. Et bien sûr, c’est l’endroit idéal pour discuter de votre visite à M autour d’un café et d’un morceau de gâteau.',
  // 'visit.subtitle2': 'Découvrez Barbóék chez M',
  // 'visit.paragraph2': 'Vous pouvez acheter des souvenirs du musée, ainsi que des livres d’art et des publications en rapport avec M et les expositions à M. Et bien sûr, c’est l’endroit idéal pour discuter de votre visite à M autour d’un café et d’un morceau de gâteau.',

  // SCAN
  'scan.title': 'Scannez ou recherchez',
  'scan.label': 'Ou entrer le numéro de l’œuvre',
  'scan.placeholder': 'Numéro de l’œuvre',
  'scan.loading': 'Recherche en cours...',
  'scan.error': 'Aucune œuvre trouvée',
  'scan.loadingCamera': 'Chargement...',
  'scan.errorOther': 'Quelque chose s’est mal passé, veuillez réessayer',

  // EXPO
  'expo.label': 'Exposition',
  'expo.tours': 'Tours',
  'expo.artworks': 'Œuvres dans cette exposition',
  'expo.activities': 'Activités à M',

  // ARTWORK
  'artwork.info': "Plus d'informations sur l'œuvre",
  'artwork.video': 'Regarder la vidéo',
  'artwork.specifications': 'Voir les spécifications',

  // AUDIOPLAYER
  'audioplayer.transcriptShow': 'Affichez le texte',
  'audioplayer.transcriptHide': 'Fermez le texte',

  // TOUR
  'tour.start': 'Démarrer la visite',
  'tour.content': 'Dans cette visite',
  'tour.stop': 'Arrêter la visite',
  // 'tour.end': 'Finir la visite',
  'tour.to': 'Aller à',
  // 'tour.ending': 'C’est la fin de la visite',
  'tour.expected': 'Activités prévues',
  'tour.newsletter': 'Restez informé',
  'tour.afterwards': 'Après votre visite',
  'tour.home': 'Retour à l’accueil',
  'tour.overlay.ending': 'Arrêtez le tour',
  'tour.overlay.ending.title': 'Êtes-vous sûr de vouloir arrêter ce tour ?',
  'tour.overlay.ending.paragraph': '',
  'tour.overlay.ending.yes': 'Oui, je veux arrêter le tour',
  'tour.overlay.ending.no': 'Non, je n’ai pas fini',
  'tour.overlay.floorplan': "Plan d'etage",
  'tour.overlay.overview': "Vue d'ensemble du tour",
  'tour.overlay.scan': 'Scannez ou recherchez',
  'tour.overlay.settings': 'Paramètres',

  // INSTALL
  'download.prompt': 'Téléchargez l’application',
  'install.title': 'Installez l’application!',
  'install.text': 'Vous pouvez maintenant installer cette application sur votre appareil pour un accès plus facile et une meilleure expérience. Cliquez sur le bouton ci-dessous pour démarrer l’installation.',
  'install.cta': 'Installer',

  // specs
  'specs.header': 'Données Techniques',
  'specs.identification': 'Identification',
  'specs.title': 'Titre',
  'specs.objectNames': "Nom de l'objet",
  'specs.location': 'Location',
  'specs.creation': 'Création',
  'specs.creators': 'Artiste(s)',
  'specs.period': 'Période de temps',
  'specs.date': 'Periode',
  'specs.date.from': 'de',
  'specs.date.to': 'à',
  'specs.technical': 'Matériel et techniques',
  'specs.materials': 'Matériel',
  'specs.techs': 'Technique',
  'specs.dimensions': 'Dimensions',

  // errors
  'error.404': "Malheureusement, cette page n'existe pas (ou plus).",
  'error.500': "Malheureusement, quelque chose s'est mal passé lors de la récupération des données correctes.",

  'error.general': 'Quelque chose s’est mal passé',
  'error.notfound': 'Page non trouvée',
  'error.home': 'Retour à l’accueil',
};
