import mainStore from '../../stores/mainStore';
import Floorplan from '../Floorplan/Floorplan.vue';
import Newsletter from '../Newsletter/Newsletter.vue';
import AboutM from '../AboutM/AboutM.vue';

export default {
  props: {},

  components: {
    Floorplan,
    Newsletter,
    AboutM,
  },

  mounted() {},

  data() {
    const store = mainStore();

    return {
      store,
      showFloorplan: false,
      showOnboarding: false,
      showNewsletter: false,
      showAboutM: false,
    };
  },

  computed: {
    info: {
      get() {
        return this.store.settings.info;
      },
    },
    hasOverlay: {
      cache: false,
      get() {
        return this.showFloorplan || this.showAboutM || this.showOnboarding || this.showNewsletter;
      },
    },
    floorplanHasData: {
      cache: false,
      get() {
        return this.store.settings.floorplan.items?.length > 0;
      },
    },
  },

  emits: ['closeInfo'],

  methods: {
    closeInfo() {
      this.$emit('closeInfo');
    },
    closeOverlay(val) {
      switch (val) {
        case 'floorplan':
          this.showFloorplan = false;
          break;
        case 'newsletter':
          this.showNewsletter = false;
          break;
        case 'aboutM':
          this.showAboutM = false;
          break;
        default:
          break;
      }
    },
  },

  watch: {},
};
