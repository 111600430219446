<template>
  <div class="paragraphs">
    <div v-for="paragraph in paragraphs" :key="paragraph.uuid">
      <div v-if="paragraph.type && paragraph.type === 'text_app'" v-html="paragraph.text"></div>
      <div v-if="paragraph.type && paragraph.type === 'media_app'">
        <Cover :autoHeight="true" v-if="(paragraph.media && paragraph.media.type && paragraph.media.type === 'resourcespace') || paragraph.media.type === 'image'" :image="paragraph.media" />
        <div v-if="paragraph.media && paragraph.media.type && paragraph.media.type === 'video_embed'" class="paragraphs__video">
          <iframe ref="videoEmbedRef" width="100%" height="100%" :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoID(paragraph.media.url)}?enablejsapi=1`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div v-if="paragraph.media && paragraph.media.type && paragraph.media.type === 'video'" class="paragraphs__video">
          <video controls width="100%">
            <source :src="paragraph.media.url" type="video/mp4" />
          </video>
        </div>
        <div class="paragraphs__audio" v-if="paragraph.media && paragraph.media.type && paragraph.media.type === 'audio'" @click="playAudio(paragraph.media)">
          <img src="../../assets/icons/play-btn.svg" alt="play transcript" />
          <p>{{ paragraph.media.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Paragraphs.js"></script>
